//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.settings'),
        name: 'settings',
        component: () => import('@/components/settings/CustomerSettings.vue'),
        meta: {
            title: i18n.global.t('titles.settings'),
            active: 'settings',
            roles: [Roles.ROLE_CUSTOMER_SETTINGS_INDEX],
        },
    },
    {
        path: i18n.global.t('routes.landingPage'),
        name: 'landingPageDesign',
        component: () => import('@/components/landingPage/LandingPageDesign.vue'),
        meta: {
            title: i18n.global.t('titles.landingPage'),
            layout: 'clean',
            roles: [Roles.ROLE_CUSTOMER_SETTINGS_INDEX],
            coaching: 'landingPage',
        },
    },
];
