import BaseApi from '@/common/api/BaseApi';
import {LiveCoachingInterfaces} from '@/types/common/api/LiveCoachingInterfaces';

export default class LiveCoachingApi {

    public static api(id?: string): string {
        return `${BaseApi.ROOT_URL}/api/live-coaching${id !== undefined ? `/${id}` : ''}`;
    }

    public static extended(data: LiveCoachingInterfaces): string {
        return `${this.api()}/extended/${data.id}?date=${data.date}&start=${data.start}&end=${data.end}`;
    }

    public static cancel(data: LiveCoachingInterfaces): string {
        return `${this.api()}/cancel/${data.id}?date=${data.date}&start=${data.start}&end=${data.end}`;
    }

    public static calendarSettings(): string {
        return `${this.api()}/calendar-settings`;
    }
}
