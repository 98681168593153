<template>
  <v-dialog v-model="fullViewCalendar" scrollable>
    <template #activator="{ props }">
      <v-btn color="primary" v-bind="props">
        {{ $t("calendar.button") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("liveCoaching.headline") }}</v-card-title>
      <v-card-text>
        <cc-calendar/>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="elevated" color="default" @click="fullViewCalendar = false">
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import CcCalendar from '@/components/common/calendar/CcCalendar.vue';
import {ref} from 'vue';

const fullViewCalendar = ref(false);
</script>

<style scoped>

</style>
