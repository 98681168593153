import Swal from 'sweetalert2';
import i18n from '@/common/i18n';

export default class SessionInvalidErrorDialog {

    constructor() {
        this._init();
    }

    _init() {
        Swal.fire({
            title: i18n.global.t('common.error.invalidSession'),
            icon: 'info',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
            didClose() {
                location.reload();
            },
        });
    }

}
