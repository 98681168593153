import {createRouter, createWebHistory} from 'vue-router';
import {LoginManager} from 'client-core';

import commonRoutes from './routes/common';
import customerRoutes from './routes/customer';
import moduleRoutes from './routes/module';
import userRoutes from './routes/user';
import notificationRoutes from './routes/notification';
import fileSystemRoutes from './routes/fileSystem';
import liveCoachingRoutes from './routes/liveCoaching';
import recipeRoutes from './routes/recipe';
import diaryRoutes from './routes/diary';
import settingsRoutes from './routes/settings';
import agreementRoutes from './routes/agreement';
import courseRoutes from './routes/course';
import statisticRoutes from './routes/statistic';
import changelogRoutes from './routes/changelog';

const vueRouter = createRouter({
    history: createWebHistory(),
    routes: [
        ...commonRoutes,
        ...customerRoutes,
        ...moduleRoutes,
        ...userRoutes,
        ...notificationRoutes,
        ...fileSystemRoutes,
        ...liveCoachingRoutes,
        ...recipeRoutes,
        ...diaryRoutes,
        ...settingsRoutes,
        ...agreementRoutes,
        ...courseRoutes,
        ...statisticRoutes,
        ...changelogRoutes,
    ],
});

vueRouter.beforeEach((to, from, next) => {
    const title = to.meta.hasOwnProperty('title') ? to.meta.title : '';
    document.title = `${title} - Communtiy Coaching`;
    if(to.matched.some(record => record.meta.noAuthRequired)) {
        next();
    } else {
        if(!LoginManager.isLoggedIn()) {
            next({name: 'login'});
        } else {
            next();
        }
    }
});

vueRouter.afterEach(() => {
    if(LoginManager.isLoggedIn()) {
        const accessToken = LoginManager.getAccessToken();
        LoginManager.setAccessToken(accessToken !== undefined ? accessToken : '');
    }
});

export default vueRouter;
