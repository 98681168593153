//================= [JS] ======================================
import i18n from '@/common/i18n';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.agreement'),
        name: 'agreement',
        component: () => import('@/components/agreement/CustomerAgreement.vue'),
        meta: {
            title: i18n.global.t('titles.agreement'),
            active: 'agreement',
        },
    },
];
