import {SharedEvents} from 'coaching-shared';
import {CoreEvents} from 'client-core';

const CcEvents = Object.freeze({
    NOTIFY: 'notify',
    RELOAD_FOOTER_LINKS: 'reloadFooterLinks',
    RELOAD_SOCIAL_MEDIA: 'reloadSocialMedia',
    RELOAD_PARTNER_SHOPS: 'reloadPartnerShops',
    RELOAD_MODULES: 'reloadModules',
    LIST_VIEW_TOGGLE: 'listViewToggle',
    SET_FOOTER_PROPS: 'setFooterProps',
    REMOVED: 'removed',
    CLOSE_DIALOG: 'closeDialog',
    SUBMIT: 'submit',
    CHANGELOG_ENTRY_SUBMITTED: 'changelogEntrySubmitted',
    ON_FINISH: 'onFinish',
    LIST_VIEW_LOADED: 'listViewLoaded',
    START_TOUR: 'startTour',
    CTX_CANCEL: 'ctx-cancel',
    CTX_CLOSE: 'ctx-close',
    CTX_OPEN: 'ctx-open',
    FILE_INFO: 'file-info',
    ADD_FILE: 'addFile',
    REMOVE_FILE: 'removeFile',
    ANSWER_CHECKED: 'answerChecked',
    MODULE_CHAPTER_STEP_EXAM_QUESTION_ANSWER_REMOVE: 'moduleChapterStepExamQuestionAnswerRemove',
    MODULE_CHAPTER_STEP_EXAM_QUESTION_REMOVE: 'ModuleChapterStepExamQuestionRemove',
    RELOAD_MODULE_CHAPTER_STEP_PREVIEW_MODULE: 'reloadModuleChapterStepPreviewModule',
    RELOAD_MODULE_CHAPTER_STEP_USER: 'reloadModuleChapterStepUser',
    USER_MODULE_CHAPTER_STEP_DONE: 'userModuleChapterStepDone',
    COURSE_MODULE_LOADED: 'courseModulesLoaded',
    DIARY_CALENDAR_REFRESH_EVENTS: 'diaryCalendarRefreshEvents',
    CLOSE_DIARY_FORM: 'closeDiaryForm',
    DIARY_FORM_IMAGE_REMOVE: 'diaryFormImageRemove',
    RELOAD_DIARY: 'reloadDiary',
    FILE_SYSTEM_UPLOAD_SUCCESS: 'fileSystemUploadSuccess',
    CLOSE_FILE_SYSTEM_FORM: 'closeFileSystemForm',
    RELOAD_FILE_SYSTEM_DATA: 'reloadFileSystemData',
    REMOVE_COMPONENT: 'removeComponent',
    MOVE_COMPONENT: 'moveComponent',
    DROP_COMPONENT: 'dropComponent',
    LIVE_COACHING_FORM_SUBMITTED: 'liveCoachingFormSubmitted',
    CLOSE_LIVE_COACHING_FORM: 'closeLiveCoachingForm',
    MODULE_CHAPTER_FORM_SUBMITTED: 'moduleChapterFormSubmitted',
    CLOSE_MODULE_CHAPTER_FORM: 'closeModuleChapterForm',
    MODULE_CHAPTER_STEP_ATTACHMENT_REMOVE: 'moduleChapterStepAttachmentRemove',
    MODULE_CHAPTER_STEP_CHECKLIST_REMOVE: 'moduleChapterStepChecklistRemove',
    SET_QUESTION_POINTS: 'SetQuestionsPoints',
    MODULE_CHAPTER_STEP_MOVED: 'moduleChapterStepMoved',
    RELOAD_NOTIFICATION_LIST: 'reloadNotificationList',
    CLOSE_NOTIFICATION_FORM: 'closeNotificationForm',
    RELOAD_COOKBOOK: 'reloadCookbook',
    COOKBOOK_FORM_SUBMITTED: 'cookbookFormSubmitted',
    CLOSE_COOKBOOK_FORM: 'closeCookbookForm',
    BOOKMARK_ADDED: 'bookmarkAdded',
    RECIPE_RATED: 'recipeRated',
    RECIPE_FORM_IMAGE_REMOVE: 'recipeFormImageRemove',
    RECIPE_FORM_IMAGE_TITLE_CHANGED: 'recipeFormImageTitleChanged',
    RECIPE_FORM_INGREDIENT_REMOVE: 'recipeFormIngredientRemove',
    RECIPE_FORM_NUTRITIONAL_VALUE_REMOVE: 'recipeFormNutritionalValueRemove',
    RELOAD_SETTINGS_FOOTER_LINKS: 'reloadSettingsFooterLinks',
    RELOAD_SETTINGS_PARTNER_SHOPS: 'reloadSettingsPartnerShops',
    RELOAD_SETTINGS_SOCIAL_MEDIA: 'reloadSettingsSocialMedia',
    USER_COURSES_LOADED: 'userCoursesLoaded',
    USER_MODULES_LOADED: 'userModulesLoaded',
    CLOSE_COURSE_PLAN: 'closeCoursePlan',
    LOAD_COURSE_PLAN: 'loadCoursePlan',
    TWO_FA_STATE_CHANGED: 'twoFaStateChanged',
    CLOSE_SETTINGS_FOOTER_LINK_FORM: 'closeSettingsFooterLinkForm',
    CLOSE_SETTINGS_PARTNER_SHOP_FORM: 'closeSettingsPartnerShopForm',
    CLOSE_SETTINGS_SOCIAL_MEDIA_FORM: 'closeSettingsSocialMediaForm',
    TIP_TAP_SETTINGS_WIDTH_CHANGED: 'tipTapWidthChanged',
    TIP_TAP_SETTINGS_ALIGN_CHANGED: 'tipTapAlignChanged',
    TIP_TAP_SETTINGS_MENU_CLOSE: 'tipTapSettingsClose',
    TIP_TAP_SUBMIT_FILES: 'tipTapSubmitFiles',
    TIP_TAP_FILE_SELECT_OPEN: 'tipTapFileSelectOpen',
    TIP_TAP_FILE_SELECT_CLOSE: 'tipTapFileSelectClose',
    TREE_LIST_DRAG_START: 'treeListDragStart',
    TREE_LIST_DRAG_END: 'treeListDragEnd',
    MODULE_PREVIEW_EXAM_VALIDATE: 'modulePreviewExamValidate',
    USER_MODULE_EXAM_VALIDATE: 'userModuleExamValidate',
    TIP_TAP_UPDATE_EDITOR: 'tipTapUpdateEditor',
});

export const Events = Object.freeze({
    ...CcEvents,
    ...SharedEvents,
    ...CoreEvents,
});

export type Events = typeof Events;
