import {createI18n} from 'vue-i18n';

import ccCommon from './de/common/common.json';
import sharedCommon from 'coaching-shared/src/common/i18n/de/common.json';
import coreCommon from 'client-core/src/common/i18n/de/common.json';
import ccTable from 'client-core/src/common/i18n/de/form/table.json';
import ccEditor from 'client-core/src/common/i18n/de/form/editor.json';
import ccForm from 'client-core/src/common/i18n/de/form/form.json';

import sharedEditor from 'coaching-shared/src/common/i18n/de/form/editor.json';
import sharedForm from 'coaching-shared/src/common/i18n/de/form/form.json';

const common = Object.assign({}, ccCommon, sharedCommon, coreCommon);

const editor = Object.assign({}, ccEditor, sharedEditor);
const form = Object.assign({}, ccForm, sharedForm);

export default createI18n<false>({
    locale: 'de',
    legacy: false,
    messages: {
        de: {
            common: common,
            login: require('./de/common/login.json'),
            register: require('./de/common/register.json'),
            passwordForgot: require('./de/common/passwordForgot.json'),
            password: require('./de/common/password.json'),
            coaching: require('./de/common/coaching.json'),
            calendar: require('./de/common/calendar.json'),
            types: require('./de/common/types.json'),
            // layout
            routes: require('./de/layout/routes.json'),
            titles: require('./de/layout/titles.json'),
            navigation: require('./de/layout/navigation.json'),
            layout: {
                tour: require('./de/layout/tour.json'),
            },
            // form
            table: ccTable,
            form: form,
            editor: editor,
            liveCoaching: {
                ...require('./de/liveCoaching/list.json'),
                form: require('./de/liveCoaching/form.json'),
            },
            module: {
                ...require('./de/module/list.json'),
                form: require('./de/module/form.json'),
                preview: require('./de/module/preview.json'),
                chapter: {
                    ...require('./de/module/chapter.json'),
                    step: require('./de/module/step.json'),
                },
            },
            customer: {
                ...require('./de/customer/list.json'),
                form: require('./de/customer/form.json'),
            },
            user: {
                ...require('./de/user/list.json'),
                form: require('./de/user/form.json'),
                progress: require('./de/user/progress.json'),
                module: require('./de/user/module.json'),
                diary: require('./de/user/diary.json'),
                profile: require('./de/user/profile.json'),
                switch: require('./de/user/switch.json'),
                dashboard: require('./de/user/dashboard.json'),
            },
            recipe: {
                ...require('./de/recipe/recipe.json'),
                cookbook: require('./de/recipe/cookbook.json'),
            },
            diary: {
                ...require('./de/diary/list.json'),
                form: require('./de/diary/form.json'),
                show: require('./de/diary/show.json'),
            },
            notification: {
                ...require('./de/notification/list.json'),
                form: require('./de/notification/form.json'),
            },
            settings: {
                ...require('./de/settings/settings.json'),
            },
            agreement: {
                ...require('./de/agreement/agreement.json'),
            },
            course: {
                ...require('./de/course/list.json'),
                form: require('./de/course/form.json'),
            },
            statistic: {
                ...require('./de/statistic/list.json'),
                device: require('./de/statistic/device.json'),
                user: require('./de/statistic/user.json'),
                userOnlineTime: require('./de/statistic/userOnlineTime.json'),
            },
            fileSystem: {
                ...require('./de/fileSystem/list.json'),
                form: require('./de/fileSystem/form.json'),
            },
            landingPage: {
                ...require('./de/landingPage/landingPage.json'),
            },
            changelog: {
                ...require('./de/changelog/list.json'),
                form: require('./de/changelog/form.json'),
                whatsNew: require('./de/changelog/whatsNew.json'),
            },
            tiptap: require('./de/common/tiptap.json'),
        },
    },
});
