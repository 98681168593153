import moment, {Moment} from 'moment/moment';
import {ref} from 'vue';
import i18n from '@/common/i18n';
import {ILiveCoaching, IWeekdays} from '@/types/components/liveCoaching/ILiveCoaching';

export const summary = ref('');
export const weekdaysI18n: IWeekdays = {
    mo: i18n.global.t('liveCoaching.form.weekdays.monday'),
    tu: i18n.global.t('liveCoaching.form.weekdays.tuesday'),
    we: i18n.global.t('liveCoaching.form.weekdays.wednesday'),
    th: i18n.global.t('liveCoaching.form.weekdays.thursday'),
    fr: i18n.global.t('liveCoaching.form.weekdays.friday'),
    sa: i18n.global.t('liveCoaching.form.weekdays.saturday'),
    su: i18n.global.t('liveCoaching.form.weekdays.sunday'),
};
export const titleRules = [
    // @ts-ignore
    v => !!v || i18n.global.t('liveCoaching.form.title.rule'),
];
export const dateRules = [
    // @ts-ignore
    v => !!v || i18n.global.t('liveCoaching.form.date.rule'),
];
export const startRules = [
    // @ts-ignore
    v => !!v || i18n.global.t('liveCoaching.form.start.rule'),
];
export const endRules = [
    // @ts-ignore
    v => !!v || i18n.global.t('liveCoaching.form.end.rule'),
];

export function buildSummary(liveCoaching: ILiveCoaching) {
    moment.locale('de');
    let text = '';
    if(!liveCoaching.permanent) {
        text = i18n.global.t('liveCoaching.form.summary.oneTime', {date: liveCoaching.date, start: liveCoaching.start, end: liveCoaching.end});
    } else {
        if(liveCoaching.frequency === 'daily') {
            const includedWeekdaysI18n: string[] = [];
            if(liveCoaching.include.length > 0) {
                liveCoaching.include.forEach((weekday) => {
                    // @ts-ignore
                    includedWeekdaysI18n.push(weekdaysI18n[weekday]);
                });
            }
            text = i18n.global.t('liveCoaching.form.summary.daily', {start: liveCoaching.start, end: liveCoaching.end, days: includedWeekdaysI18n.join(', ')});
        } else if(liveCoaching.frequency === 'weekly') {
            let calendarDate: string | undefined = '';
            if(liveCoaching.date !== '' && liveCoaching.date !== null)
                calendarDate = getFormattedDate(liveCoaching.date)?.format('dddd');
            text = i18n.global.t('liveCoaching.form.summary.weekly', {date: calendarDate, start: liveCoaching.start, end: liveCoaching.end});
        } else if(liveCoaching.frequency === 'monthly') {
            let calendarDate: string | undefined = '';
            if(liveCoaching.date !== '' && liveCoaching.date !== null)
                calendarDate = getFormattedDate(liveCoaching.date)?.format('DD.');
            text = i18n.global.t('liveCoaching.form.summary.monthly', {date: calendarDate, start: liveCoaching.start, end: liveCoaching.end});
        }
    }
    summary.value = text;
}

export function getFormattedDate(date: string): Moment | undefined {
    if(date.includes('-'))
        return moment(date, 'YYYY-MM-DD');
    else if(date.includes('.')) {
        return moment(date, 'DD.MM.YYYY');
    }
}
