<template>
  <v-row :data-v-step="tourStep">
    <v-col cols="12" class="text-center" v-if="controlRows">
      <h1>{{ calendarTitle }}</h1>
    </v-col>
    <v-col cols="12" v-if="controlRows">
      <v-btn :color="isToday ? 'default' : 'primary'" @click="today(fullCalendarRef.getApi())" :disabled="isToday" class="float-left mr-5">
        {{ $t('calendar.today') }}
      </v-btn>
      <div class="cc-btn-group">
        <v-btn color="primary" @click="prev(fullCalendarRef.getApi())">
          <v-icon :icon="Icons.CHEVRON_LEFT"/>
        </v-btn>
        <v-btn color="primary" @click="next(fullCalendarRef.getApi())">
          <v-icon :icon="Icons.CHEVRON_RIGHT"/>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" sm="6" lg="4" v-if="!controlRows">
      <v-btn :color="isToday ? 'default' : 'primary'" @click="today(fullCalendarRef.getApi())" :disabled="isToday" class="float-left mr-5">
        {{ $t('calendar.today') }}
      </v-btn>
      <div class="cc-btn-group">
        <v-btn color="primary" @click="prev(fullCalendarRef.getApi())">
          <v-icon :icon="Icons.CHEVRON_LEFT"/>
        </v-btn>
        <v-btn color="primary" @click="next(fullCalendarRef.getApi())">
          <v-icon :icon="Icons.CHEVRON_RIGHT"/>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" sm="6" lg="4" class="text-center" v-if="!controlRows">
      <h1>{{ calendarTitle }}</h1>
    </v-col>
    <v-col cols="12" sm="12" lg="4" class="text-right" v-if="!controlRows">
      <div class="cc-btn-group" v-if="canViewChange">
        <v-btn :color="currentView === 'dayGridMonth' ? 'primary' : 'default'" @click="changeView('dayGridMonth', fullCalendarRef.getApi())">
          {{ $t('calendar.view.month') }}
        </v-btn>
        <v-btn :color="currentView === 'timeGridWeek' ? 'primary' : 'default'" @click="changeView('timeGridWeek', fullCalendarRef.getApi())">
          {{ $t('calendar.view.week') }}
        </v-btn>
        <v-btn :color="currentView === 'timeGridDay' ? 'primary' : 'default'" @click="changeView('timeGridDay', fullCalendarRef.getApi())">
          {{ $t('calendar.view.day') }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
  <full-calendar ref="fullCalendarRef" v-if="dataLoaded" :options="calendarOptions"/>
  <cc-loading-data-spinner v-else/>
  <cc-context-menu id="contextMenu" ref="contextMenuRef" @ctx-open="ctxMenuOpen">
    <live-coaching-details ref="liveCoachingDetailsRef"/>
    <live-coaching-edit ref="liveCoachingEditRef" v-if="canEdit"/>
    <v-divider v-if="canDelete"/>
    <template v-if="contextMenuData.type === 'cancel' && canDelete">
      <v-list-item @click="deleteExtended('cancel')">
        <template #prepend>
          <v-icon :icon="Icons.CALENDAR_REFRESH_OUTLINE"/>
        </template>
        <v-list-item-title>{{ $t('calendar.cancel.delete.list') }}</v-list-item-title>
      </v-list-item>
    </template>
    <template v-else-if="contextMenuData.type === 'extended' && canDelete">
      <v-list-item @click="deleteExtended('extended')">
        <template #prepend>
          <v-icon :icon="Icons.DELETE"/>
        </template>
        <v-list-item-title>{{ $t('calendar.extended.delete.list') }}</v-list-item-title>
      </v-list-item>
    </template>
    <template v-else>
      <cc-list-delete-item ref="listDeleteItemRef" :title="eventTitle" event-type="calendar" :type="$t('types.liveCoaching')" :id="contextMenuData.id" :path="liveCoachingApiPath" v-if="canDelete"/>
    </template>
  </cc-context-menu>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================
import {ApiClient, Icons, LoginManager, ResponseVerifier, SweetAlertConfig, useEventBus} from 'client-core';
import LiveCoachingApi from '@/common/api/liveCoaching/LiveCoachingApi';
import moment from 'moment';
import LiveCoachingExtendedApi from '@/common/api/liveCoaching/LiveCoachingExtendedApi';
import Swal from 'sweetalert2';
//================= [VUE COMPONENTS] ==========================
import FullCalendar from '@fullcalendar/vue3';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import deLocale from '@fullcalendar/core/locales/de';
import LiveCoachingDetails from '@/components/liveCoaching/LiveCoachingDetails.vue';
import LiveCoachingEdit from '@/components/liveCoaching/LiveCoachingEdit.vue';
import {computed, onBeforeMount, onMounted, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {calendarTitle, changeView, checkDate, currentView, isToday, next, prev, setCurrentView, today} from '@/composables/common/calendar/useCalendar';
import {CalendarOptions} from '@fullcalendar/core';
import CcContextMenu from '@/components/common/calendar/CcContextMenu.vue';
import {Events} from '@/common/utils/Events';
import {ICustomerCalendarSettings} from '@/types/components/customer/ICustomer';
import {AxiosResponse} from 'axios';
import {IContextMenuData} from '@/types/components/liveCoaching/ILiveCoaching';

const i18n = useI18n();
const eventBus = useEventBus();

const props = defineProps({
  'canDelete': {
    type: Boolean,
  },
  'canEdit': {
    type: Boolean,
  },
  'initialView': {
    type: String,
    default: 'timeGridWeek',
  },
  'canViewChange': {
    type: Boolean,
    default: true,
  },
  'controlRows': {
    type: Boolean,
    default: false,
  },
  'tourStep': {
    type: [String, Number],
    default: null,
  },
});

const liveCoachingDetailsRef = ref();
const liveCoachingEditRef = ref();
const contextMenuRef = ref();
const listDeleteItemRef = ref();
const fullCalendarRef = ref();
const dataLoaded = ref<boolean>(false);
setCurrentView(props.initialView as string);

const calendarOptions = {
  events: `${LiveCoachingApi.api()}?token=${LoginManager.getAccessToken()}`,
  plugins: [rrulePlugin, timeGridPlugin, dayGridPlugin],
  initialView: props.initialView,
  firstDay: 1,
  locale: deLocale,
  allDaySlot: false,
  weekNumbers: true,
  slotDuration: '00:15:00',
  slotLabelFormat: {
    hour: 'numeric',
    minute: 'numeric',
  },
  slotLabelInterval: {
    hours: 1,
  },
  headerToolbar: {
    start: '',
    center: '',
    end: '',
  },
  eventDidMount: (info) => {
    info.el.addEventListener('contextmenu', (event) => {
      event.preventDefault();
      const date = moment(info.event.start).format('DD.MM.YYYY');
      const start = moment(info.event.start).format('HH:mm');
      const end = moment(info.event.end).format('HH:mm');
      contextMenuRef.value.open(event, {
        'id': info.event.id,
        'start': start,
        'end': end,
        'date': date,
        'type': info.event.extendedProps.type,
        'frequency': info.event.extendedProps.frequency !== undefined ? info.event.extendedProps.frequency : '',
      });
    });
  },
  eventClassNames: (arg) => {
    if(arg.event.extendedProps.type === 'cancel')
      return 'canceledEvent';
  },
  eventClick: (info) => {
    liveCoachingDetailsRef.value.setEvent({id: info.event.id, type: info.event.extendedProps.type});
    liveCoachingDetailsRef.value.openDialog();
  },
  eventContent: (arg) => {
    let arrayOfDomNodes: HTMLElement[] = [];
    let frequencyText = '';
    if(arg.event.extendedProps.hasOwnProperty('frequency'))
      frequencyText = ` (${i18n.t(`liveCoaching.form.frequency.${arg.event.extendedProps.frequency}`)})`;
    if(arg.view.type === 'dayGridMonth') {
      const dot = document.createElement('div');
      dot.classList.add('fc-daygrid-event-dot');
      const time = document.createElement('div');
      time.classList.add('fc-event-time');
      let timeText = arg.timeText;
      if(!arg.timeText.includes(i18n.t('common.clock')))
        timeText += ` ${i18n.t('common.clock')}`;
      time.innerHTML = timeText;
      const title = document.createElement('div');
      title.classList.add('fc-event-title');
      title.innerHTML = `${arg.event.title}${frequencyText}`;

      arrayOfDomNodes = [dot, time, title];
    } else if(arg.view.type === 'timeGridWeek' || arg.view.type === 'timeGridDay') {
      const mainFrame = document.createElement('div');
      mainFrame.classList.add('fc-event-main-frame');
      const eventTime = document.createElement('div');
      eventTime.innerHTML = arg.timeText;
      const eventTitleContainer = document.createElement('div');
      eventTitleContainer.classList.add('fc-event-title-container');
      const eventTitle = document.createElement('div');
      eventTitle.classList.add('fc-event-title', 'fc-sticky');
      eventTitle.innerHTML = `${arg.event.title}${frequencyText}`;
      eventTitleContainer.appendChild(eventTitle);
      mainFrame.appendChild(eventTime);
      mainFrame.appendChild(eventTitleContainer);

      arrayOfDomNodes = [mainFrame];
    }

    return {domNodes: arrayOfDomNodes};
  },
} as CalendarOptions;
const contextMenuData = ref<IContextMenuData>({});
const liveCoachingApiPath = LiveCoachingApi.api();

const eventTitle = computed(() => {
  return contextMenuData.value.frequency === '' ? i18n.t('calendar.delete.list') : i18n.t('calendar.series.delete.list');
});

const loadCalendarSettings = async() => {
  const response = <AxiosResponse<ICustomerCalendarSettings>>await ApiClient.get<ICustomerCalendarSettings>(LiveCoachingApi.calendarSettings());

  if(ResponseVerifier.verify(response, true)) {
    calendarOptions.slotMinTime = response.data.start;
    calendarOptions.slotMaxTime = response.data.end;
    calendarOptions.scrollTime = moment().format('HH:mm:ss');
    dataLoaded.value = true;
  }
};

const ctxMenuOpen = (data: IContextMenuData) => {
  contextMenuData.value = data;
  liveCoachingDetailsRef.value.setEvent(data);
  if(props.canEdit)
    liveCoachingEditRef.value.setEvent(data);
  listDeleteItemRef.value.setTitle(eventTitle);
};

const refreshData = () => {
  dataLoaded.value = false;
  fullCalendarRef.value.getApi().refetchEvents();
  dataLoaded.value = true;
};

const deleteExtended = async(type: string) => {
  await Swal.fire({
    title: i18n.t(`calendar.${type}.delete.title`),
    html: i18n.t(`calendar.${type}.delete.text`),
    ...SweetAlertConfig.delete(i18n.t(`calendar.${type}.delete.confirm`)),
    preConfirm: async() => {
      const response = await ApiClient.delete(LiveCoachingExtendedApi.api(contextMenuData.value.id));
      if(ResponseVerifier.verify(response))
        refreshData();
    },
  });
};

onBeforeMount(() => {
  loadCalendarSettings();
});

onMounted(() => {
  const interval = setInterval(() => {
    if(fullCalendarRef.value !== undefined) {
      clearInterval(interval);
      checkDate(fullCalendarRef.value.calendar);
    }
  }, 50);

  eventBus.on(`${Events.ENTRY_DELETE}-calendar`, () => {
    refreshData();
  });
});

defineExpose({refreshData});
</script>

<style scoped>

</style>
