//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.user.userList'),
        name: 'userList',
        component: () => import('@/components/user/UserList.vue'),
        meta: {
            title: i18n.global.t('titles.user.userList'),
            active: 'user',
            roles: [Roles.ROLE_USER_INDEX],
        },
    },
    {
        path: i18n.global.t('routes.user.userCreate'),
        name: 'userCreate',
        component: () => import('@/components/user/UserCreate.vue'),
        meta: {
            title: i18n.global.t('titles.user.userCreate'),
            active: 'user',
            roles: [Roles.ROLE_USER_CREATE],
        },
    },
    {
        path: i18n.global.t('routes.user.userEdit'),
        name: 'userEdit',
        component: () => import('@/components/user/UserEdit.vue'),
        meta: {
            title: i18n.global.t('titles.user.userEdit'),
            active: 'user',
            roles: [Roles.ROLE_USER_EDIT],
        },
    },
    {
        path: i18n.global.t('routes.user.userProgress'),
        name: 'userProgress',
        component: () => import('@/components/user/UserProgress.vue'),
        meta: {
            title: i18n.global.t('titles.user.userProgress'),
            active: 'user',
            roles: [Roles.ROLE_USER_INDEX],
        },
    },
    {
        path: i18n.global.t('routes.user.userDiary'),
        name: 'userDiary',
        component: () => import('@/components/user/diary/UserDiary.vue'),
        meta: {
            title: i18n.global.t('titles.user.userDiary'),
            active: 'user',
            roles: [Roles.ROLE_USER_INDEX],
        },
    },
    {
        path: i18n.global.t('routes.user.userProfile'),
        name: 'userProfile',
        component: () => import('@/components/user/profile/UserProfile.vue'),
        meta: {
            title: i18n.global.t('titles.user.userProfile'),
            layout: 'flex',
        },
    },
    {
        path: i18n.global.t('routes.user.userModules'),
        name: 'userModules',
        component: () => import('@/components/user/module/UserModules.vue'),
        meta: {
            title: i18n.global.t('titles.user.userModules'),
            active: 'userModule',
            layout: 'user',
        },
    },
    {
        path: i18n.global.t('routes.user.userModuleShow'),
        name: 'userModuleShow',
        component: () => import('@/components/user/module/UserModuleShow.vue'),
        meta: {
            title: i18n.global.t('titles.user.userModuleShow'),
            active: 'userModule',
            layout: 'user',
        },
    },
    {
        path: i18n.global.t('routes.user.moduleChapterStepUserShow'),
        name: 'moduleChapterStepUserShow',
        component: () => import('@/components/common/module/chapter/step/user/ModuleChapterStepUserShow.vue'),
        meta: {
            title: i18n.global.t('titles.user.moduleChapterStepUserShow'),
            active: 'userModule',
            layout: 'user',
        },
    },
    {
        path: i18n.global.t('routes.user.moduleChapterStepUserExam'),
        name: 'moduleChapterStepUserExam',
        component: () => import('@/components/common/module/chapter/step/exam/ModuleChapterStepUserExam.vue'),
        meta: {
            title: i18n.global.t('titles.user.moduleChapterStepUserExam'),
            active: 'userModule',
            layout: 'user',
        },
    },
    {
        path: i18n.global.t('routes.user.userDashboard'),
        name: 'userDashboard',
        component: () => import('@/components/user/UserDashboard.vue'),
        meta: {
            title: i18n.global.t('titles.user.userDashboard'),
            active: 'userDashboard',
            layout: 'user',
        },
    },
];
