<template>
  <v-form ref="form" @submit.prevent="submit()">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field :label="$t('liveCoaching.form.title.label') + $t('form.required')" v-model.trim="liveCoachingExtended.title" :rules="titleRules" variant="outlined" counter
                        maxlength="250"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field :label="$t('liveCoaching.form.url')" v-model.trim="liveCoachingExtended.url" variant="outlined" counter maxlength="250"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <cc-editor v-model="liveCoachingExtended.description"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <cc-datepicker :label="$t('liveCoaching.form.date.label') + $t('form.required')" v-model="liveCoachingExtended.date" :rules="dateRules"/>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" sm="6" class="pt-0">
          <cc-timepicker :label="$t('liveCoaching.form.start.label') + $t('form.required')" v-model="liveCoachingExtended.start" :max="liveCoachingExtended.end" :rules="startRules"/>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <cc-timepicker :label="$t('liveCoaching.form.end.label') + $t('form.required')" v-model="liveCoachingExtended.end" :min="liveCoachingExtended.start" :rules="endRules"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 v-dompurify-html="summary"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="elevated" color="default" @click="closeDialog()">
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn variant="elevated" color="primary" type="submit" :loading="formLoading">
        {{ $t('liveCoaching.edit.headline') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================
import moment from 'moment';
import 'moment/locale/de.js';
import {ResponseVerifier, useEventBus, ErrorDialog, ValidationErrorDialog, ApiClient} from 'client-core';
import LiveCoachingExtendedApi from '@/common/api/liveCoaching/LiveCoachingExtendedApi';
import {ILiveCoachingExtended} from '@/types/components/liveCoaching/ILiveCoaching';
import {Events} from '@/common/utils/Events';
//================= [VUE COMPONENTS] ==========================
import {inject, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {dateRules, endRules, startRules, summary, titleRules} from '@/composables/liveCoaching/baseLiveCoaching';

const i18n = useI18n();
const eventBus = useEventBus();

const form = ref();
const formLoading = ref(false);
const liveCoachingExtended = inject('liveCoachingExtended').value as ILiveCoachingExtended;

const submit = async() => {
  const formValid = await form.value.validate();
  if(!formValid) {
    new ValidationErrorDialog();
    return null;
  }

  formLoading.value = true;
  const timeStartMoment = moment(liveCoachingExtended.value.start, 'HH:mm');
  const timeEndMoment = moment(liveCoachingExtended.value.end, 'HH:mm');

  const durationMoment = moment.duration(timeEndMoment.diff(timeStartMoment));
  const minutes = durationMoment.asMinutes();
  const hours = Math.floor(minutes / 60);
  const restMinutes = Number(minutes) - Number(hours) * 60;
  let minuteAppend = '';
  let minutePrepend = '';
  if(restMinutes === 0) {
    minuteAppend = '0';
  } else if(restMinutes < 10) {
    minutePrepend = '0';
  }

  let duration = `${hours}`;
  if(hours.toString().length === 1)
    duration = `0${hours}`;
  duration += `:${minutePrepend}${restMinutes}${minuteAppend}`;

  liveCoachingExtended.value.duration = duration;
  const response = await ApiClient.config({
    method: liveCoachingExtended.value.id === '' ? 'POST' : 'PUT',
    url: LiveCoachingExtendedApi.api(liveCoachingExtended.value.id),
    data: liveCoachingExtended,
  }).catch(() => {
    new ErrorDialog();
  });
  if(ResponseVerifier.verify(response)) {
    eventBus.emit(Events.NOTIFY, i18n.t('liveCoaching.form.extended.success'));
    formLoading.value = false;
    eventBus.emit(Events.LIVE_COACHING_FORM_SUBMITTED);
    closeDialog();
  }
};

const closeDialog = () => {
  form.value.reset();
  eventBus.emit(Events.CLOSE_LIVE_COACHING_FORM);
};
</script>

<style scoped>

</style>
