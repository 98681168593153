//================= [JS] ======================================
import i18n from '@/common/i18n';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.diary.diary'),
        name: 'diary',
        component: () => import('@/components/diary/MyDiary.vue'),
        meta: {
            title: i18n.global.t('titles.diary.diary'),
            active: 'diary',
            coaching: 'diary',
            layout: 'user',
        },
    },
    {
        path: i18n.global.t('routes.diary.diaryShow'),
        name: 'diaryShow',
        component: () => import('@/components/diary/DiaryShow.vue'),
        meta: {
            title: i18n.global.t('titles.diary.diaryShow'),
            active: 'diary',
            coaching: 'diary',
            layout: 'flex',
        },
    },
];
