<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template #activator="{ props }">
      <v-list-item v-bind="props" @click="loadLiveCoaching()">
        <template #prepend>
          <v-icon :icon="Icons.EYE"/>
        </template>
        <v-list-item-title>{{ $t("liveCoaching.details.headline") }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <template v-if="liveCoaching !== undefined">
        <v-card-title v-dompurify-html="liveCoaching.title"/>
        <v-card-text>
          <v-row>
            <v-col>
              <h3 v-dompurify-html="liveCoaching.summary"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3 v-dompurify-html="liveCoaching.description"/>
            </v-col>
          </v-row>
          <v-btn tag="a" target="_blank" color="primary" :href="liveCoaching.url" v-if="liveCoaching.url !== ''">
            {{ $t("liveCoaching.details.join") }}
          </v-btn>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title></v-card-title>
        <cc-loading-data-spinner/>
      </template>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" @click="closeDialog()">
          {{ $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================
import LiveCoachingApi from '@/common/api/liveCoaching/LiveCoachingApi';
import {ApiClient, Icons, ResponseVerifier} from 'client-core';
import LiveCoachingExtendedApi from '@/common/api/liveCoaching/LiveCoachingExtendedApi';
//================= [VUE COMPONENTS] ==========================
import {ILiveCoaching} from '@/types/components/liveCoaching/ILiveCoaching';
import {ref} from 'vue';

const dialog = ref(false);
const liveCoaching = ref<ILiveCoaching>();
const event = ref();

const setEvent = (e) => {
  event.value = e;
};

const loadLiveCoaching = async() => {
  const response = await ApiClient.get(event.value.type === 'normal' ? LiveCoachingApi.api(event.value.id) : LiveCoachingExtendedApi.api(event.value.id));

  if(ResponseVerifier.verify(response, true))
    liveCoaching.value = response.data;
};

const closeDialog = () => {
  event.value = null;
  liveCoaching.value = undefined;
  dialog.value = false;
};

const openDialog = () => {
  loadLiveCoaching();
  dialog.value = true;
};

defineExpose({openDialog, setEvent});

</script>

<style scoped>

</style>
