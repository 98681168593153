declare global {
    interface Array<T> {
        removeItem(value: any): T[];

        reOrder(props: { type: string, order: number }): T[];

        removeOrderEntry(order: number): T[];

        orderBy(property: string): T[];

        fixOrder(): T[];
    }

    interface Date {
        getWeekNumber(): number;
    }
}
export {};


Array.prototype.removeItem = function(value: any) {
    const index = this.indexOf(value);
    if(index > -1)
        this.splice(index, 1);

    return this;
};

Array.prototype.reOrder = function(props: { type: string, order: number }) {
    this.forEach((entry) => {
        if(props.type === 'up') {
            if(entry.order === props.order)
                entry.order += 1;
            else if(entry.order === (props.order + 1))
                entry.order -= 1;
        } else if(props.type === 'down') {
            if(entry.order === props.order)
                entry.order -= 1;
            else if(entry.order === (props.order - 1))
                entry.order += 1;
        }
    });
    return this;
};

Array.prototype.removeOrderEntry = function(order: number) {
    let indexToRemove = 0;
    this.forEach((entry, index) => {
        if(entry.order > order) {
            entry.order -= 1;
        } else if(entry.order === order) {
            indexToRemove = index;
        }
    });
    this.splice(indexToRemove, 1);
    return this;
};

Array.prototype.orderBy = function(property: string) {
    this.sort((a, b) => (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0));
    return this;
};

Array.prototype.fixOrder = function() {
    this.forEach((entry, index) => {
        entry.order = index;
    });
    return this;
};

Date.prototype.getWeekNumber = function() {
    const d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // @ts-ignore
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
};
