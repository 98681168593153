//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.liveCoaching'),
        name: 'liveCoaching',
        component: () => import('@/components/liveCoaching/LiveCoaching.vue'),
        meta: {
            title: i18n.global.t('titles.liveCoaching'),
            active: 'liveCoaching',
            roles: [Roles.ROLE_LIVE_COACHING_INDEX],
        },
    },
];
