//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.notification'),
        name: 'notification',
        component: () => import('@/components/notification/NotificationList.vue'),
        meta: {
            title: i18n.global.t('titles.notification'),
            active: 'notification',
            roles: [Roles.ROLE_NOTIFICATION_INDEX],
        },
    },
];
