<template>
  <v-card ref="contextMenu" @click.stop @contextmenu.stop :id="id" :style="ctxStyle" class="ctx-menu-container">
    <v-list class="ctx-menu" :class="{'ctx-menu-right': align==='right', 'ctx-menu-left': align==='left'}">
      <slot name="default">

      </slot>
    </v-list>
  </v-card>
</template>

<script setup lang="ts">
import {computed, nextTick, ref, watch} from 'vue';
import {Events} from '@/common/utils/Events';

defineProps({
  'id': {
    type: String,
    default: 'default-ctx',
  },
});

const emits = defineEmits([Events.CTX_CANCEL, Events.CTX_CLOSE, Events.CTX_OPEN]);

const ctxLeft = ref<number>(0);
const ctxTop = ref<number>(0);
const ctxVisible = ref<boolean>(false);
const align = 'left';
let locals = {};
const contextMenu = ref();

watch(() => ctxVisible, (newVal, oldVal) => {
  if(oldVal === true && newVal === false) {
    bodyClickListener.stop(() => {
      // console.log('context menu sequence finished', e)
      // this.locals = {}
    });
  }
});

const ctxStyle = computed(() => {
  return {
    'display': ctxVisible.value ? 'block' : 'none',
    'top': (ctxTop.value || 0) + 'px',
    'left': (ctxLeft.value || 0) + 'px',
  };
});

const setPositionFromEvent = (e) => {
  e = e || window.event;

  const scrollingElement = document.scrollingElement || document.documentElement;

  if(e.pageX || e.pageY) {
    ctxLeft.value = e.pageX;
    ctxTop.value = e.pageY - scrollingElement.scrollTop;
  } else if(e.clientX || e.clientY) {
    ctxLeft.value = e.clientX + scrollingElement.scrollLeft;
    ctxTop.value = e.clientY + scrollingElement.scrollTop;
  }


  nextTick(() => {
    const menu = contextMenu.value.$el;
    const minHeight = (menu.style.minHeight || menu.style.height).replace('px', '') || 32;
    const minWidth = (menu.style.minWidth || menu.style.width).replace('px', '') || 32;
    const scrollHeight = menu.scrollHeight || minHeight;
    const scrollWidth = menu.scrollWidth || minWidth;

    const largestHeight = window.innerHeight - scrollHeight - 25;
    const largestWidth = window.innerWidth - scrollWidth - 25;

    if(ctxTop.value > largestHeight)
      ctxTop.value = largestHeight;
    if(ctxLeft.value > largestWidth)
      ctxLeft.value = largestWidth;
  });
  return e;
};

const open = (event, data) => {
  if(ctxVisible.value)
    ctxVisible.value = false;
  ctxVisible.value = true;
  emits(Events.CTX_OPEN, locals = data || {});
  setPositionFromEvent(event);
  contextMenu.value.$el.setAttribute('tab-index', -1);
  bodyClickListener.start();
};

const createBodyClickListener = (fn) => {
  let isListening = false;

  /* === public api ========================================== */
  return {
    get isListening() {
      return isListening;
    },

    start(cb = null) {
      window.addEventListener('click', _onclick, true);
      window.addEventListener('keyup', _onescape, true);
      isListening = true;
      if(typeof cb === 'function') cb();
    },

    stop(cb) {
      window.removeEventListener('click', _onclick, true);
      window.removeEventListener('keyup', _onescape, true);
      isListening = false;
      if(typeof cb === 'function') cb();
    },
  };

  /* === private helpers ===================================== */
  function _onclick(e) {
    e.preventDefault();
    if(typeof fn === 'function') fn(e);
  }

  function _onescape(e) {
    if(e.keyCode === 27) _onclick(e);
  }
};

const bodyClickListener = createBodyClickListener(
  (e) => {
    const isOpen = !!ctxVisible.value;
    const outsideClick = isOpen && !contextMenu.value.$el.contains(e.target);

    if(outsideClick) {
      if(e.which !== 1) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      } else {
        ctxVisible.value = false;
        emits(Events.CTX_CANCEL, locals);
        e.stopPropagation();
      }
    } else {
      ctxVisible.value = false;
      emits(Events.CTX_CLOSE, locals);
    }
  }
);

defineExpose({open});

</script>

<style scoped lang="scss">
.ctx {
  position: relative;
}

.ctx-menu {
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  font-size: .9rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -moz-box-shadow: 0 0 5px #CCC;
  -webkit-box-shadow: 0 0 5px #CCC;
  box-shadow: 0 0 5px #CCC
}

.ctx-divider {
  height: 1px;
  margin: .5rem 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.ctx-item {
  display: block;
  /*width: 100%;*/
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  cursor: default;
}

.ctx-item:focus,
.ctx-item:hover {
  color: #2b2d2f;
  text-decoration: none;
  background-color: #f5f5f5;
  cursor: normal;
}

.ctx-item.active,
.ctx-item.active:focus,
.ctx-item.active:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0275d8;
  outline: 0;
}

.ctx-item.disabled,
.ctx-item.disabled:focus,
.ctx-item.disabled:hover {
  color: #818a91;
}

.ctx-item.disabled:focus,
.ctx-item.disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)";
}

.open > .ctx-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.ctx-menu-right {
  right: 0;
  left: auto;
}

.ctx-menu-left {
  right: auto;
  left: 0;
}

.ctx-header {
  display: block;
  padding: 3px 20px;
  font-size: .9rem;
  line-height: 1.5;
  color: #818a91;
  white-space: nowrap;
}

.ctx-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right > .ctx-menu {
  right: 0;
  left: auto;
}

.ctx-menu-container {
  position: fixed;
  padding: 0;
  border: 1px solid #bbb;
  background-color: whitesmoke;
  z-index: 99999;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
</style>
