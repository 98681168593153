//================= [JS] ======================================
import i18n from '@/common/i18n';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.statistic.statistic'),
        name: 'statistic',
        component: () => import('@/components/statistic/StatisticDashboard.vue'),
        meta: {
            title: i18n.global.t('titles.statistic.statistic'),
            active: 'statistic',
            coaching: 'statistic',
            userType: 'admin',
        },
    },
    {
        path: i18n.global.t('routes.statistic.statisticDevice'),
        name: 'statisticDevice',
        component: () => import('@/components/statistic/types/StatisticDevice.vue'),
        meta: {
            title: i18n.global.t('titles.statistic.statisticDevice'),
            active: 'statistic',
            coaching: 'statistic',
            userType: 'admin',
        },
    },
    {
        path: i18n.global.t('routes.statistic.statisticUser'),
        name: 'statisticUser',
        component: () => import('@/components/statistic/types/StatisticUser.vue'),
        meta: {
            title: i18n.global.t('titles.statistic.statisticUser'),
            active: 'statistic',
            coaching: 'statistic',
            userType: 'admin',
        },
    },
    {
        path: i18n.global.t('routes.statistic.statisticUserOnlineTime'),
        name: 'statisticUserOnlineTime',
        component: () => import('@/components/statistic/types/StatisticUserOnlineTime.vue'),
        meta: {
            title: i18n.global.t('titles.statistic.statisticUserOnlineTime'),
            active: 'statistic',
            coaching: 'statistic',
            userType: 'admin',
        },
    },
];
