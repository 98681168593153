//================= [JS] ======================================
import i18n from '@/common/i18n';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('client-core/src/components/NotFound/NotFound.vue'),
        meta: {
            layout: 'clean',
            noAuthRequired: true,
        },
    },
    {
        path: i18n.global.t('routes.base'),
        redirect: i18n.global.t('routes.login'),
    },
    {
        path: i18n.global.t('routes.login'),
        name: 'login',
        component: () => import('@/components/SignIn.vue'),
        meta: {
            noAuthRequired: true,
            layout: 'clean',
            title: i18n.global.t('titles.login'),
        },
    },
    {
        path: i18n.global.t('routes.passwordForgot'),
        name: 'passwordForgot',
        component: () => import('@/components/PasswordForgot.vue'),
        meta: {
            noAuthRequired: true,
            layout: 'clean',
            title: i18n.global.t('titles.passwordForgot'),
        },
    },
    {
        path: i18n.global.t('routes.password'),
        name: 'password',
        component: () => import('@/components/SetPassword.vue'),
        meta: {
            layout: 'clean',
            title: i18n.global.t('titles.password'),
        },
    },
    {
        path: i18n.global.t('routes.coaching'),
        name: 'coaching',
        component: () => import('@/components/CoachingApproval.vue'),
        meta: {
            noAuthRequired: true,
            layout: 'clean',
            title: i18n.global.t('titles.coaching'),
        },
    },
    {
        path: i18n.global.t('routes.register'),
        name: 'register',
        component: () => import('@/components/UserRegister.vue'),
        meta: {
            noAuthRequired: true,
            layout: 'clean',
            title: i18n.global.t('titles.register'),
        },
    },
];
