<template>
  <v-form ref="form" @submit.prevent="submit()">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-switch color="primary" class="mt-0 pt-0 mb-0" v-model="liveCoaching.permanent"
                    :label="liveCoaching.permanent ? $t('liveCoaching.form.permanent') : $t('liveCoaching.form.oneTime')"
                    @change="!liveCoaching.permanent ? liveCoaching.frequency = '' : liveCoaching.frequency = 'daily'"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field :label="$t('liveCoaching.form.title.label') + $t('form.required')" v-model.trim="liveCoaching.title" :rules="titleRules" variant="outlined" counter maxlength="250"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field :label="$t('liveCoaching.form.url')" v-model.trim="liveCoaching.url" variant="outlined" counter maxlength="250"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <cc-editor v-model="liveCoaching.description"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <cc-datepicker :label="$t('liveCoaching.form.date.label') + $t('form.required')" v-model="liveCoaching.date" :rules="dateRules"/>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" sm="6" class="pt-0">
          <cc-timepicker :label="$t('liveCoaching.form.start.label') + $t('form.required')" v-model="liveCoaching.start" :max="liveCoaching.end" :rules="startRules"/>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0">
          <cc-timepicker :label="$t('liveCoaching.form.end.label') + $t('form.required')" v-model="liveCoaching.end" :min="liveCoaching.start" :rules="endRules"/>
        </v-col>
      </v-row>
      <v-row v-if="liveCoaching.permanent">
        <v-col cols="12" lg="6">
          <h2>{{ $t('liveCoaching.form.frequency.label') }}</h2>
          <v-radio-group v-model="liveCoaching.frequency">
            <v-radio value="daily" :label="$t('liveCoaching.form.frequency.daily')"></v-radio>
            <v-radio value="weekly" :label="$t('liveCoaching.form.frequency.weekly')"></v-radio>
            <v-radio value="monthly" :label="$t('liveCoaching.form.frequency.monthly')"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" lg="6" v-if="liveCoaching.frequency === 'daily'">
          <h2>{{ $t('liveCoaching.form.weekdays.label') }}</h2>
          <v-checkbox class="mb-n9" @click="toggleWeekday('mo');" :model-value="liveCoaching.include.includes('mo')" :label="$t('liveCoaching.form.weekdays.monday')"></v-checkbox>
          <v-checkbox class="mb-n9" @click="toggleWeekday('tu');" :model-value="liveCoaching.include.includes('tu')" :label="$t('liveCoaching.form.weekdays.tuesday')"></v-checkbox>
          <v-checkbox class="mb-n9" @click="toggleWeekday('we');" :model-value="liveCoaching.include.includes('we')" :label="$t('liveCoaching.form.weekdays.wednesday')"></v-checkbox>
          <v-checkbox class="mb-n9" @click="toggleWeekday('th');" :model-value="liveCoaching.include.includes('th')" :label="$t('liveCoaching.form.weekdays.thursday')"></v-checkbox>
          <v-checkbox class="mb-n9" @click="toggleWeekday('fr');" :model-value="liveCoaching.include.includes('fr')" :label="$t('liveCoaching.form.weekdays.friday')"></v-checkbox>
          <v-checkbox class="mb-n9" @click="toggleWeekday('sa');" :model-value="liveCoaching.include.includes('sa')" :label="$t('liveCoaching.form.weekdays.saturday')"></v-checkbox>
          <v-checkbox class="mb-n9" @click="toggleWeekday('su');" :model-value="liveCoaching.include.includes('su')" :label="$t('liveCoaching.form.weekdays.sunday')"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 v-dompurify-html="summary"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="elevated" color="default" @click="closeDialog()">
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn variant="elevated" color="primary" type="submit" :loading="formLoading">
        {{ type === 'create' ? $t('liveCoaching.create.headline') : $t('liveCoaching.edit.headline') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================
import moment from 'moment';
import 'moment/locale/de.js';
import LiveCoachingApi from '@/common/api/liveCoaching/LiveCoachingApi';
import {ResponseVerifier, useEventBus, ErrorDialog, ValidationErrorDialog, ApiClient} from 'client-core';
import {Events} from '@/common/utils/Events';
//================= [VUE COMPONENTS] ==========================
import {inject, onMounted, ref, watch} from 'vue';
import {buildSummary, dateRules, endRules, startRules, summary, titleRules} from '@/composables/liveCoaching/baseLiveCoaching';
import {ILiveCoaching} from '@/types/components/liveCoaching/ILiveCoaching';

const props = defineProps({
  'type': {
    required: true,
  },
});

const eventBus = useEventBus();
const form = ref();
const formLoading = ref(false);
const liveCoaching = inject('liveCoaching').value as ILiveCoaching;

watch(liveCoaching, () => {
  buildSummary(liveCoaching);
}, {deep: true});

const submit = async() => {
  const formValid = await form.value.validate();
  if(!formValid.valid) {
    new ValidationErrorDialog();
    return null;
  }

  formLoading.value = true;
  const timeStartMoment = moment(liveCoaching.start, 'HH:mm');
  const timeEndMoment = moment(liveCoaching.end, 'HH:mm');

  const durationMoment = moment.duration(timeEndMoment.diff(timeStartMoment));
  const minutes = durationMoment.asMinutes();
  const hours = Math.floor(minutes / 60);
  const restMinutes = Number(minutes) - Number(hours) * 60;
  let minuteAppend = '';
  let minutePrepend = '';
  if(restMinutes === 0) {
    minuteAppend = '0';
  } else if(restMinutes < 10) {
    minutePrepend = '0';
  }

  let duration = `${hours}`;
  if(hours.toString().length === 1)
    duration = `0${hours}`;
  duration += `:${minutePrepend}${restMinutes}${minuteAppend}`;

  liveCoaching.duration = duration;
  const response = await ApiClient.config({
    method: props.type === 'create' ? 'POST' : 'PUT',
    url: LiveCoachingApi.api(props.type === 'edit' ? liveCoaching.id : undefined),
    data: liveCoaching,
  }).catch(() => {
    formLoading.value = false;
    new ErrorDialog();
  });

  if(ResponseVerifier.verify(response)) {
    formLoading.value = false;
    eventBus.emit(Events.LIVE_COACHING_FORM_SUBMITTED);
    form.value.reset();
    closeDialog();
  }
};
const closeDialog = () => {
  form.value.reset();
  eventBus.emit(Events.CLOSE_LIVE_COACHING_FORM);
};
const toggleWeekday = (weekday) => {
  if(!liveCoaching.include.includes(weekday))
    liveCoaching.include.push(weekday);
  else
    liveCoaching.include.removeItem(weekday);
};

onMounted(() => {
  buildSummary(liveCoaching);
});

defineExpose({buildSummary});

</script>

<style scoped>

</style>
