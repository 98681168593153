export default class BaseApi {

    public static ROOT_URL: string = process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:8000';
    public static IMAGE_URL: string = process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:8080';

    public static login(): string {
        return `${BaseApi.ROOT_URL}/api/v2/login`;
    }

    public static passwordForgot(): string {
        return `${BaseApi.ROOT_URL}/api/v2/password-forgot`;
    }

    public static verifyUser(): string {
        return `${BaseApi.ROOT_URL}/api/v2/verify-user`;
    }

    public static userData(): string {
        return `${BaseApi.ROOT_URL}/api/v2/user-data`;
    }

    public static passwordSet(): string {
        return `${BaseApi.ROOT_URL}/api/v2/password-set`;
    }

    public static validateToken(): string {
        return `${BaseApi.ROOT_URL}/api/v2/validate-token`;
    }

    public static customerInformation(): string {
        return `${BaseApi.ROOT_URL}/api/v2/customer-information`;
    }

    public static coachingApproved(): string {
        return `${BaseApi.ROOT_URL}/api/v2/coaching-approved`;
    }

    public static register(): string {
        return `${BaseApi.ROOT_URL}/api/v1/register`;
    }
}
