import {createApp} from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import coachingShared from './plugins/coachingShared';
import clientCore from './plugins/clientCore';
import router from './common/router';
import i18n from './common/i18n';
import {createPinia} from 'pinia';
import '@/types/common/extensions';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import MainLayout from '@/layout/MainLayout.vue';
import UserLayout from '@/layout/UserLayout.vue';
import axios from 'axios';
import SessionInvalidErrorDialog from '@/common/utils/SessionInvalidErrorDialog';

const app = createApp(App);

const Emitter = require('tiny-emitter');
const emitter = new Emitter();
app.config.globalProperties.emitter = emitter;

//================= [DOM PURIFY] ======================================

app.use(VueDOMPurifyHTML);

//================= [AXIOS] ======================================

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if(error.response.status === 401) { //UNAUTHORIZED
            new SessionInvalidErrorDialog();
            return;
        }
        return Promise.reject(error);
    }
);

//================= [LAYOUTS] ======================================

app.component('main-layout', MainLayout);
app.component('user-layout', UserLayout);

//================= [INITIALIZE APP] ======================================

const pinia = createPinia();

app.use(vuetify);
app.use(coachingShared);
app.use(clientCore);
app.use(router);
app.use(i18n);
app.use(pinia);

app.mount('#app');
