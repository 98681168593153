<template>
  <v-app v-if="initialized" id="user-application">
    <v-container fluid class="navigation-container">
      <v-container>
        <v-navigation-drawer app absolute temporary v-model="showSideNav">
          <v-img class="h-max-xl-125" :src="logoUrl()"/>
          <v-list nav>
            <template v-for="(item, i) in items" :key="i">
              <v-list-group v-if="item.hasOwnProperty('items')">
                <template #activator="{props}">
                  <v-list-item v-bind="props">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item v-for="(subItem, subI) in item.items" :key="subI" :to="{name: subItem.path}" exact>
                  <template #prepend>
                    <v-icon :icon="subItem.icon"/>
                  </template>
                  <v-list-item-title>
                    {{ subItem.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-item v-else :to="{name:item.path}" exact>
                <template #prepend>
                  <v-icon :icon="item.icon"/>
                </template>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item tag="a" @click="logout()" class="d-flex d-md-none mt-5">
              <template #prepend>
                <v-icon :icon="Icons.LOGOUT"/>
              </template>
              <v-list-item-title>
                {{ $t('navigation.logout') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-app-bar app>
          <div class="w-40 w-sm-25 w-md-25 w-lg-20 w-xl-15 h-max-xl-80 mr-5">
            <router-link :to="{name: 'userDashboard'}">
              <v-img class="h-max-xl-125" :src="logoUrl()"/>
            </router-link>
          </div>
          <template v-for="(item, i) in items" :key="i">
            <v-menu v-if="item.hasOwnProperty('items')" nudge-bottom="30">
              <template #activator="{props}">
                <div v-bind="props" class="pr-5 menu-dropdown d-none d-lg-block">
                  <span>{{ item.title }}</span>
                  <v-icon :icon="Icons.CHEVRON_DOWN"/>
                </div>
              </template>
              <v-list>
                <v-list-item v-for="(subItem, subIndex) in item.items" :key="subIndex" :to="{name: subItem.path}">
                  <v-list-item-title>
                    {{ subItem.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <router-link class="pr-5 d-none d-lg-block" v-else :to="{name:item.path}" exact>
              <span>{{ item.title }}</span>
            </router-link>
          </template>
          <v-spacer/>
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-icon v-bind="props" class="mr-7 second-menu-item" v-if="store.usn !== undefined" @click="returnUser()" :icon="Icons.ACCOUNT_SWITCH"/>
            </template>
            <span>{{ $t('navigation.tooltip.userSwitch', {name: store.usn}) }}</span>
          </v-tooltip>
          <menu-partner-shops/>
          <menu-calendar/>
          <menu-notifications/>
          <v-menu offset-y>
            <template #activator="{props}">
              <v-icon v-bind="props" size="large" class="d-none d-lg-block second-menu-item mr-3" :icon="Icons.ACCOUNT_CIRCLE"/>
            </template>
            <v-list>
              <v-list-item :to="{name: 'userProfile'}">
                <template #prepend>
                  <v-icon :icon="Icons.ACCOUNT_CIRCLE"/>
                </template>
                <v-list-item-title>
                  {{ $t('navigation.profile') }}
                </v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item tag="a" @click="logout()">
                <template #prepend>
                  <v-icon :icon="Icons.LOGOUT"/>
                </template>
                <v-list-item-title>
                  {{ $t('navigation.logout') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-icon class="d-block d-lg-none mr-3" @click="showSideNav = !showSideNav" size="large" :icon="Icons.ACCOUNT_CIRCLE"/>
        </v-app-bar>
      </v-container>
    </v-container>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer color="primary" absolute data-v-main-step="4">
      <v-card color="primary" width="100%" flat>
        <v-card-text class="text-center" v-if="socialMedias.length > 0">
          <v-btn variant="text" size="large" :href="socialMedia.url" target="_blank" v-for="socialMedia in socialMedias" :key="socialMedia.id" :icon="Icons[socialMedia.icon.toUpperCase()]"/>
        </v-card-text>
        <v-card-text class="text-center" v-if="footerLinks.length > 0">
          <a :href="footerLink.url" target="_blank" :class="`text-h6 ${footerLinks.length > 1 ? 'mr-10' : ''}`" v-for="footerLink in footerLinks" :key="footerLink.id"
             v-dompurify-html="footerLink.name"/>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================
import SecUtil from '@/common/utils/security/SecUtil';
import {Icons} from 'client-core';
//================= [VUE COMPONENTS] ==========================
import {footerLinks, logoUrl, returnUser, socialMedias} from '@/composables/layout/baseLayout';
import {initialized, logout} from '@/composables/layout/layoutHandler';
import {computed, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import {useCoachingStore} from '@/common/store';
import MenuCalendar from '@/layout/menu/MenuCalendar.vue';
import MenuNotifications from '@/layout/menu/MenuNotifications.vue';
import MenuPartnerShops from '@/layout/menu/MenuPartnerShops.vue';

const i18n = useI18n();
const store = useCoachingStore();

const showSideNav = ref(false);

const items = computed(() => {
  const navigation = [];

  navigation.push({title: i18n.t('navigation.userDashboard'), path: 'userDashboard', icon: Icons.BALLOT, active: 'userDashboard'});
  navigation.push({title: i18n.t('navigation.userModules'), path: 'userModules', icon: Icons.BALLOT, active: 'userModule'});
  if(SecUtil.coaching('diary'))
    navigation.push({title: i18n.t('navigation.diary'), path: 'diary', icon: Icons.NOTEBOOK, active: 'diary'});

  if(SecUtil.coaching('recipe')) {
    navigation.push({
      title: i18n.t('navigation.recipe.label'), icon: Icons.CHEF_HAT,
      active: ['recipe', 'cookbook'],
      items: [
        {title: i18n.t('navigation.recipe.recipeList'), path: 'recipeList', icon: Icons.CHEF_HAT, active: 'recipe'},
        {title: i18n.t('navigation.recipe.cookBook'), path: 'cookbook', icon: Icons.BOOK_OPEN_VARIANT, active: 'cookbook'},
      ],
    });
  }
  return navigation;
});

</script>

<style lang="scss">
@import "@/assets/scss/user.scss";
</style>
