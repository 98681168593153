import {defineStore} from 'pinia';
import {ref} from 'vue';
import {IUserData} from '@/types/composables/layout/IBaseLayout';
import {IImage} from '@/types/components/common/IImage';

export const useCoachingStore = defineStore('useCoachingStore', () => {
    const user = ref<string>();
    const customer = ref<string>();
    const roles = ref<string[]>([]);
    const coaching = ref({
        corporateIdentity: false,
        course: false,
        diary: false,
        recipe: false,
        statistic: false,
        exam: false,
        notification: false,
    });
    const contact = ref({firstname: '', lastname: ''});
    const redirect = ref<string>();// redirect to userModules or module
    const type = ref<string>('user'); // type is user or admin
    const usn = ref<string>();// user switch name
    const mu = ref(false);// main user for access the agreement
    const pcr = ref(false); //password change required
    const listSettings = ref({}); //the list settings per entry
    const image = ref<IImage>();
    const logout = () => {
        user.value = undefined;
        customer.value = undefined;
        roles.value = [];
        coaching.value = {
            corporateIdentity: false,
            course: false,
            diary: false,
            recipe: false,
            statistic: false,
            exam: false,
            notification: false,
        };
        redirect.value = undefined;
        type.value = 'user';
        usn.value = undefined;
        mu.value = false;
        pcr.value = false;
        contact.value = {
            firstname: '',
            lastname: '',
        };
    };
    const setResponseState = (data: IUserData) => {
        if(data.hasOwnProperty('u'))
            user.value = data.u;
        if(data.hasOwnProperty('r'))
            roles.value = data.r;
        if(data.hasOwnProperty('cm')) {
            // new coaching package
            coaching.value.corporateIdentity = data.cm.ci;
            coaching.value.course = data.cm.c;
            coaching.value.diary = data.cm.d;
            coaching.value.recipe = data.cm.r;
            coaching.value.statistic = data.cm.s;
            coaching.value.exam = data.cm.e;
            coaching.value.notification = data.cm.n;
        }
        if(data.hasOwnProperty('usn'))
            usn.value = data.usn;
        if(data.hasOwnProperty('re'))
            redirect.value = data.re;
        if(data.hasOwnProperty('t'))
            type.value = data.t === 'a' ? 'admin' : 'user';
        if(data.hasOwnProperty('mu'))
            mu.value = data.mu;
        if(data.hasOwnProperty('c'))
            customer.value = data.c;
        if(data.hasOwnProperty('pcr'))
            pcr.value = data.pcr;
        if(data.hasOwnProperty('co')) {
            contact.value.firstname = data.co.fn;
            contact.value.lastname = data.co.ln;
        }
        if(data.hasOwnProperty('image')) {
            image.value = data.image;
        }
    };
    const userLetters = () => {
        return `${contact.value.firstname.slice(0, 1).toUpperCase()}${contact.value.lastname.slice(0, 1).toUpperCase()}`;
    };

    return {user, customer, roles, coaching, redirect, type, usn, mu, pcr, listSettings, contact, logout, setResponseState, userLetters, image};
});
