<template>
  <v-avatar v-if="round" :image="src" :size="size"/>
  <v-img v-else :src="src" :contain="contain"/>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================

//================= [VUE COMPONENTS] ==========================
import {onMounted, ref, watch} from 'vue';
import BaseApi from '@/common/api/BaseApi';
import {useCoachingStore} from '@/common/store';

const store = useCoachingStore();

const props = defineProps({
  'file': {
    required: true,
    type: String,
  },
  'contain': {
    default: true,
    type: Boolean,
  },
  'mimeType': {
    type: String,
    required: true,
  },
  'path': {
    type: String,
    default: null,
  },
  'size': {
    type: Number,
    default: 200,
  },
  round: {
    type: Boolean,
    default: false,
  },
});

const src = ref('');
watch(() => props.file, (value) => {
  src.value = `${BaseApi.IMAGE_URL}/files/${store.customer}/${getFolder()}/${getValueName(value)}`;
});

const getFolder = () => {
  let folder = 'image';
  if(props.mimeType.includes('video'))
    folder = 'video';
  if(props.mimeType.includes('pdf'))
    folder = 'pdf';

  if(props.path !== null)
    folder = props.path;

  return folder;
};

const getValueName = (value) => {
  let valueName = value.replace(' ', '_');
  if(props.mimeType.includes('video') || props.mimeType.includes('pdf')) {
    const valueNameSplit = valueName.split('.');
    valueNameSplit.pop();
    valueName = valueNameSplit.join('.');
    valueName = `${valueName}_preview.jpg`;
  }
  return valueName;
};

onMounted(() => {
  src.value = `${BaseApi.IMAGE_URL}/files/${store.customer}/${getFolder()}/${getValueName(props.file)}`;
});
</script>

<style scoped lang="scss">

</style>
