//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.fileSystem'),
        name: 'fileSystemList',
        component: () => import('@/components/fileSystem/FileSystemList.vue'),
        meta: {
            title: i18n.global.t('titles.fileSystem'),
            active: 'fileSystem',
            roles: [Roles.ROLE_FILE_SYSTEM_INDEX],
        },
    },
];
