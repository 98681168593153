//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.module.moduleList'),
        name: 'moduleList',
        component: () => import('@/components/module/ModuleList.vue'),
        meta: {
            title: i18n.global.t('titles.module.moduleList'),
            active: 'module',
            roles: [Roles.ROLE_MODULE_INDEX],
        },
    },
    {
        path: i18n.global.t('routes.module.moduleEdit'),
        name: 'moduleEdit',
        component: () => import('@/components/module/ModuleEdit.vue'),
        meta: {
            title: i18n.global.t('titles.module.moduleEdit'),
            active: 'module',
            roles: [Roles.ROLE_MODULE_EDIT],
        },
    },
    {
        path: i18n.global.t('routes.module.modulePreview'),
        name: 'modulePreview',
        component: () => import('@/components/module/ModulePreview.vue'),
        meta: {
            title: i18n.global.t('titles.module.modulePreview'),
            active: 'module',
            roles: [Roles.ROLE_MODULE_EDIT, Roles.ROLE_MODULE_CREATE],
        },
    },
    {
        path: i18n.global.t('routes.module.moduleChapterStepShow'),
        name: 'moduleChapterStepModulePreviewShow',
        component: () => import('@/components/common/module/chapter/step/modulePreview/ModuleChapterStepModulePreviewShow.vue'),
        meta: {
            title: i18n.global.t('titles.module.moduleChapterStepShow'),
            active: 'module',
        },
    },
    {
        path: i18n.global.t('routes.module.moduleChapterStepCreate'),
        name: 'moduleChapterStepCreate',
        component: () => import('@/components/module/chapter/step/ModuleChapterStepCreate.vue'),
        meta: {
            title: i18n.global.t('titles.module.moduleChapterStepCreate'),
            active: 'module',
            roles: [Roles.ROLE_MODULE_EDIT, Roles.ROLE_MODULE_CREATE],
        },
    },
    {
        path: i18n.global.t('routes.module.moduleChapterStepEdit'),
        name: 'moduleChapterStepEdit',
        component: () => import('@/components/module/chapter/step/ModuleChapterStepEdit.vue'),
        meta: {
            title: i18n.global.t('titles.module.moduleChapterStepEdit'),
            active: 'module',
            roles: [Roles.ROLE_MODULE_EDIT, Roles.ROLE_MODULE_CREATE],
        },
    },
];
