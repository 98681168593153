//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.changelog.changelog'),
        name: 'changelogList',
        component: () => import('@/components/changelog/ChangelogList.vue'),
        meta: {
            title: i18n.global.t('titles.changelog.changelog'),
            active: 'changelog',
            roles: [Roles.ROLE_CHANGELOG_INDEX],
        },
    },
    {
        path: i18n.global.t('routes.changelog.changelogCreate'),
        name: 'changelogCreate',
        component: () => import('@/components/changelog/ChangelogCreate.vue'),
        meta: {
            title: i18n.global.t('titles.changelog.changelogCreate'),
            active: 'changelog',
            roles: [Roles.ROLE_CHANGELOG_CREATE],
        },
    },
    {
        path: i18n.global.t('routes.changelog.changelogEdit'),
        name: 'changelogEdit',
        component: () => import('@/components/changelog/ChangelogEdit.vue'),
        meta: {
            title: i18n.global.t('titles.changelog.changelogEdit'),
            active: 'changelog',
            roles: [Roles.ROLE_CHANGELOG_EDIT],
        },
    },
    {
        path: i18n.global.t('routes.changelog.whatsNew'),
        name: 'whatsNew',
        component: () => import('@/components/changelog/WhatsNew.vue'),
        meta: {
            title: i18n.global.t('titles.changelog.whatsNew'),
        },
    },
];
