import SecUtil from '@/common/utils/security/SecUtil';
import {Roles} from '@/common/utils/security/Roles';
import BaseApi from '@/common/api/BaseApi';
import {ref} from 'vue';
import router from '@/common/router';
import {useCoachingStore} from '@/common/store';
import {ApiClient, LoginManager} from 'client-core';
import {RouteLocationNormalizedLoaded} from 'vue-router';
import {SecUtilHasRoles} from '@/types/common/utils/SecUtilInterfaces';
import {IUserData} from '@/types/composables/layout/IBaseLayout';

export const initialized = ref(false);

export async function checkRole(to: RouteLocationNormalizedLoaded) {
    if(to.meta.hasOwnProperty('roles')) {
        let access = false;
        if(SecUtil.hasMultipleRoles({and: to.meta.roles} as SecUtilHasRoles))
            access = true;
        if(!access)
            await redirectToModule();
    }
}

export async function checkCoaching(to: RouteLocationNormalizedLoaded) {
    if(to.meta.hasOwnProperty('coaching')) {
        let access = false;
        const metaProperty = to.meta.coaching as string;
        if(SecUtil.coaching(metaProperty))
            access = true;
        if(!access)
            await redirectToModule();
    }
}

export async function checkUserType(to: RouteLocationNormalizedLoaded) {
    const store = useCoachingStore();
    if(to.meta.hasOwnProperty('userType')) {
        if(to.meta.userType !== store.type)
            await redirectToModule();
    }
}

export async function redirectToModule() {
    const store = useCoachingStore();
    if(store.redirect === 'm') {
        if(SecUtil.hasRoles(Roles.ROLE_MODULE_INDEX))
            await router.push({name: 'moduleList'});
        else
            await router.push({name: 'userProfile'});//default if is admin but no permission for module list
    } else {
        await router.push({name: 'userDashboard'});
    }
}

export async function initialize() {
    const store = useCoachingStore();
    await ApiClient.get<IUserData>(BaseApi.userData()).then(async(response) => {
        if(response.status === 200) {
            store.setResponseState(response?.data);
            initialized.value = true;
            await checkRole(router.currentRoute.value);
            await checkCoaching(router.currentRoute.value);
            await checkUserType(router.currentRoute.value);
            if(store.pcr)
                await router.push({name: 'password'});
        } else {
            logout();
        }
    }).catch(async() => {
        logout();
    });
}

export function logout() {
    const store = useCoachingStore();
    LoginManager.removeCookies();
    store.logout();
    ApiClient.logout();
    window.location.reload();
}
