import {ref} from 'vue';
import {Calendar} from '@fullcalendar/core';

export const isToday = ref(true);
export const calendarTitle = ref('');
export const currentView = ref('');

export function checkDate(calendar: Calendar) {
    const calDate = calendar.getDate();
    const today = new Date();
    if(currentView.value === 'dayGridMonth') {
        isToday.value = calDate.getMonth() === today.getMonth() && calDate.getFullYear() === today.getFullYear();
    } else if(currentView.value === 'timeGridWeek' || currentView.value === 'dayGridWeek') {
        // @ts-ignore
        isToday.value = calDate.getWeekNumber() === today.getWeekNumber() && calDate.getFullYear() === today.getFullYear();
    } else if(currentView.value === 'timeGridDay') {
        isToday.value = calDate.getMonth() === today.getMonth() && calDate.getFullYear() === today.getFullYear() && calDate.getDate() === today.getDate();
    }

    calendarTitle.value = calendar.getCurrentData().viewTitle;
}

export function changeView(view: string, calendar: Calendar) {
    calendar.changeView(view);
    currentView.value = view;
    checkDate(calendar);
}

export function today(calendar: Calendar) {
    calendar.today();
    checkDate(calendar);
}

export function prev(calendar: Calendar) {
    calendar.prev();
    checkDate(calendar);
}

export function next(calendar: Calendar) {
    calendar.next();
    checkDate(calendar);
}

export function setCurrentView(view: string) {
    currentView.value = view;
}
