<template>
  <component :is="layout">
    <router-view/>
  </component>
  <v-snackbar v-model="showToast" timer="3000" color="success" location="top">
    <template #text>
      <v-row class="d-flex align-center">
        <v-col cols="1" class="mr-2">
          <v-icon size="x-large" :icon="Icons.CHECK_CIRCLE"/>
        </v-col>
        <v-col cols="10">
          <div v-dompurify-html="toastText"></div>
        </v-col>
      </v-row>
    </template>
  </v-snackbar>
  <v-overlay v-model="layoutLoading" class="cc-application-loader align-center justify-center" scroll-strategy="block" persistent>
    <v-progress-circular size="120" width="3" color="primary" indeterminate/>
    <div class="mt-5">{{ $t('common.application.loading') }}</div>
  </v-overlay>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================
import Cookies from 'js-cookie';
import {computed, nextTick, onBeforeMount, ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {useLocale} from 'vuetify';
import {useCoachingStore} from '@/common/store';
import {ApiClient, Icons, LoginManager, useEventBus} from 'client-core';
import {checkCoaching, checkRole, checkUserType, initialize, initialized} from '@/composables/layout/layoutHandler';
import {loadFooterLinks, loadLayout, loadNotifications, loadPartnerShops, loadSocialMedia} from '@/composables/layout/baseLayout';
import {Events} from '@/common/utils/Events';
//================= [VUE COMPONENTS] ==========================
const store = useCoachingStore();
const router = useRouter();
const i18n = useI18n();
const {current} = useLocale();

const layoutLoading = ref<boolean>(false);
const eventBus = useEventBus();

const showToast = ref(false);
const toastText = ref('');

const layout = computed(() => {
  const meta = router.currentRoute.value.meta;
  if(meta.hasOwnProperty('layout')) {
    if(meta.layout === 'flex') {
      return `${store.type === 'admin' ? 'main' : 'user'}-layout`;
    } else {
      return `${meta.layout}-layout`;
    }
  }
  return 'main-layout';
});

onBeforeMount(async() => {
  watch(router.currentRoute, async(to) => {
    if(LoginManager.isLoggedIn()) {
      if(!initialized.value) {
        layoutLoading.value = true;
        await initialize();
        await loadLayout();
        layoutLoading.value = false;
        if(eventBus === undefined)
          return;
        eventBus.on(Events.NOTIFY, (text: string) => {
          showToast.value = false;
          toastText.value = '';
          nextTick(() => {
            toastText.value = text;
            showToast.value = true;
          });
        });
        eventBus.on(Events.RELOAD_FOOTER_LINKS, () => {
          loadFooterLinks();
        });
        eventBus.on(Events.RELOAD_SOCIAL_MEDIA, () => {
          loadSocialMedia();
        });
        eventBus.on(Events.RELOAD_PARTNER_SHOPS, () => {
          loadPartnerShops();
        });
      }

      await checkRole(to);
      await checkCoaching(to);
      await checkUserType(to);
      await loadNotifications();
    }
  });

  const i18nCookie = Cookies.get('i18n');
  if(!i18nCookie) {
    const expireDate = new Date();
    expireDate.setHours(expireDate.getHours() + 4);
    Cookies.set('i18n', i18n.locale.value, {expires: expireDate, SameSite: 'Strict', secure: true});
  } else {
    const availableLocales = i18n.availableLocales;
    if(availableLocales.includes(i18nCookie)) {
      i18n.locale.value = i18nCookie;
    } else {
      i18n.locale.value = 'de';
      const expireDate = new Date();
      expireDate.setHours(expireDate.getHours() + 4);
      Cookies.set('i18n', i18n.locale.value, {expires: expireDate, SameSite: 'Strict', secure: true});
    }
  }
  current.value = i18n.locale.value;
});

ApiClient.registerCacheHandler();

// BUGFIX For ResizeObserver https://github.com/vuejs/vue-cli/issues/7431
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function(...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

</script>

<style lang="scss">
@import "assets/scss/application.scss";
</style>
