import i18n from '@/common/i18n';

import {VOnboardingWrapper} from 'v-onboarding';

export {VOnboardingWrapper};

export const tourOptions = {
    labels: {
        previousButton: i18n.global.t('common.tour.previous'),
        nextButton: i18n.global.t('common.tour.next'),
        finishButton: i18n.global.t('common.tour.finish'),
    },
};
