<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <template #activator="{ props }">
            <v-list-item v-if="hasPermission" v-bind="props" @click="loadUserList()">
                <template #prepend>
                    <v-icon :icon="Icons.ACCOUNT_SWITCH"/>
                </template>
                <v-list-item-title>
                    {{ $t("navigation.userSwitch") }}
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card>
            <v-card-title>
                {{ $t("user.switch.label") }}
            </v-card-title>
            <v-card-text>
                <v-autocomplete v-model="user" :items="userList" item-value="id" item-title="name" :label="$t('user.switch.user')" class="mt-10" variant="outlined"></v-autocomplete>
                <v-autocomplete v-model="customer" :items="customerList" item-value="id" item-title="name" :disabled="customerList.length === 0" :label="$t('user.switch.customer')"
                                variant="outlined"></v-autocomplete>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="default" @click="close()">
                    {{ $t("common.close") }}
                </v-btn>
                <v-btn color="primary" :disabled="user === '' || customer === ''" @click="switchUser()" :loading="switchLoading">
                    {{ $t("user.switch.label") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
// ==================== [CSS] ===============================

// ==================== [JS] ================================
import UserSwitchApi from '@/common/api/user/UserSwitchApi';
import {Roles} from '@/common/utils/security/Roles';
import SecUtil from '@/common/utils/security/SecUtil';
// ==================== [VUE COMPONENTS] ====================
import {ref, watch} from 'vue';
import {Icons, LoginManager, ResponseVerifier, ErrorDialog, ApiClient} from 'client-core';
import {useRouter} from 'vue-router';
import {IUserSwitchCustomer, IUserSwitchUser} from '@/types/components/admin/IUserSwitch';
import {useCoachingStore} from '@/common/store';

const store = useCoachingStore();
const router = useRouter();

const dialog = ref<boolean>(false);
const user = ref<string>();
const userList = ref<IUserSwitchUser[]>([]);
const customer = ref<string>();
const customerList = ref<IUserSwitchCustomer[]>([]);
const hasPermission = store.type === 'admin' && SecUtil.hasRoles(Roles.ROLE_USER_SWITCH_INDEX) && store.usn === undefined;
const switchLoading = ref<boolean>(false);

const close = () => {
    user.value = '';
    userList.value = [];
    customer.value = '';
    customerList.value = [];
    dialog.value = false;
};
const loadUserList = async() => {
    const response = await ApiClient.get<IUserSwitchUser[]>(UserSwitchApi.api()).catch(() => {
        new ErrorDialog();
    });

    if(ResponseVerifier.verify(response, true))
        userList.value = response.data;
};

const loadCustomerList = async() => {
    customerList.value = [];
    if(user.value === '')
        return null;
    const response = await ApiClient.get<IUserSwitchCustomer[]>(UserSwitchApi.api(user.value)).catch(() => {
        new ErrorDialog();
    });

    if(ResponseVerifier.verify(response, true))
        customerList.value = response.data;
};

const switchUser = async() => {
    switchLoading.value = true;
    const response = await ApiClient.post(UserSwitchApi.api(), {
            user: user.value,
            customer: customer.value,
        }
    ).catch(() => {
        new ErrorDialog();
        switchLoading.value = false;
    });

    if(ResponseVerifier.verify(response, true)) {
        store.setResponseState(response.data);
        LoginManager.setAccessToken(response.data.accessToken);
        sessionStorage.clear();
        await router.push({name: 'login'});
    }
};

watch(user, () => {
    loadCustomerList();
});
</script>

<style scoped>

</style>
