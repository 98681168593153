import {createVuetify, IconOptions} from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import {de, en} from 'vuetify/locale';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as labsComponents from 'vuetify/labs/components';
import * as directives from 'vuetify/directives';

export default createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    directives,
    icons: <IconOptions>{
        defaultSet: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    locale: {
        locale: 'de',
        fallback: 'de',
        messages: {de, en},
    },
});
