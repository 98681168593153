//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.course.courseList'),
        name: 'courseList',
        component: () => import('@/components/course/CourseList.vue'),
        meta: {
            title: i18n.global.t('titles.course.courseList'),
            active: 'course',
            roles: [Roles.ROLE_COURSE_INDEX],
        },
    },
    {
        path: i18n.global.t('routes.course.courseEdit'),
        name: 'courseEdit',
        component: () => import('@/components/course/CourseEdit.vue'),
        meta: {
            title: i18n.global.t('titles.course.courseEdit'),
            active: 'course',
            roles: [Roles.ROLE_COURSE_EDIT],
        },
    },
];
