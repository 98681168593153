<template>
  <v-app v-if="initialized">
    <v-navigation-drawer :permanent="withSideNav" :temporary="!withSideNav" :absolute="!withSideNav" app v-model="showSideNav">
      <v-img :src="logoUrl()"/>
      <v-list nav data-v-main-step="3">
        <template v-for="(item, i) in items" :key="i">
          <v-list-group v-if="item.hasOwnProperty('items')" :prepend-icon="item.icon">
            <template #activator="{props}">
              <v-list-item v-bind="props">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item v-for="(subItem, subI) in item.items" :key="subI" :to="{name: subItem.path}" :class="isActive(subItem) ? 'v-list-item--active pl-5' : 'pl-5'" exact>
              <template #prepend>
                <v-icon :icon="subItem.icon"/>
              </template>
              <v-list-item-title>
                {{ subItem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :to="{name: item.path}" :class="`${(isActive(item) ? 'v-list-item--active' : '')} ${item.hasOwnProperty('css') ? item.css : ''}`" exact>
            <template #prepend>
              <v-icon :icon="item.icon"/>
            </template>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item tag="a" @click="logout()" class="d-flex d-md-none">
          <template #prepend>
            <v-icon :icon="Icons.LOGOUT"/>
          </template>
          <v-list-item-title>
            {{ $t('navigation.logout') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :absolute="true" app>
      <v-spacer/>
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-icon v-bind="props" :icon="Icons.BULLHORN_OUTLINE" v-if="store.type === 'admin'" class="mr-7" @click="moveToWhatsNew"/>
        </template>
        <span>{{ $t('navigation.tooltip.whatsNew') }}</span>
      </v-tooltip>
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-icon v-bind="props" v-if="store.type === 'admin'" @click="startMainTour" class="d-none d-md-block mr-7" :icon="Icons.HELP_CIRCLE_OUTLINE"/>
        </template>
        <span>{{ $t('common.help') }}</span>
      </v-tooltip>
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-icon v-bind="props" class="mr-7" v-if="store.usn !== undefined" @click="returnUser()" :icon="Icons.ACCOUNT_SWITCH"/>
        </template>
        <span>{{ $t('navigation.tooltip.userSwitch', {name: store.usn}) }}</span>
      </v-tooltip>
      <menu-partner-shops/>
      <menu-calendar/>
      <menu-notifications/>
      <v-menu offset-y>
        <template #activator="{props}">
          <cc-image v-if="store.image.name !== null" v-bind="props"
                    class="d-none d-md-block mr-3" data-v-main-step="0" :file="store.image?.name" :mime-type="store.image?.mimeType" :path="`user/${store.user}`" round :size="30"/>
          <v-icon v-else v-bind="props" size="large" class="d-none d-md-block mr-3" data-v-main-step="0" :icon="Icons.ACCOUNT_CIRCLE"/>
        </template>
        <v-list>
          <v-list-item :to="{name: 'userProfile'}">
            <template #prepend>
              <v-icon :icon="Icons.ACCOUNT_CIRCLE"/>
            </template>
            <v-list-item-title>
              {{ $t('navigation.profile') }}
            </v-list-item-title>
          </v-list-item>
          <UserSwitch/>
          <v-divider/>
          <v-list-item tag="a" @click="logout()">
            <template #prepend>
              <v-icon :icon="Icons.LOGOUT"/>
            </template>
            <v-list-item-title>
              {{ $t('navigation.logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <cc-image v-if="store.image.name !== null" @click="showSideNav = !showSideNav"
                class="d-block d-md-none mr-3 m-sm-0" :file="store.image?.name" :mime-type="store.image?.mimeType" :path="`user/${store.user}`" round :size="30"/>
      <v-icon v-else class="d-block d-md-none mr-3 m-sm-0" @click="showSideNav = !showSideNav" size="large" :icon="Icons.ACCOUNT_CIRCLE"/>
    </v-app-bar>
    <v-main>
      <router-view/>
      <v-onboarding-wrapper ref="mainOnboardingWrapper" v-if="store.type === 'admin'" :steps="tourSteps" :options="tourOptions"/>
    </v-main>
    <v-footer color="primary" data-v-main-step="4">
      <v-card color="primary" width="100%" flat :class="withSideNav ? 'cc-footer-card' : ''">
        <v-card-text class="text-center" v-if="socialMedias.length > 0">
          <v-btn variant="text" size="large" :href="socialMedia.url" target="_blank" v-for="socialMedia in socialMedias" :key="socialMedia.id" :icon="Icons[socialMedia.icon.toUpperCase()]"/>
        </v-card-text>
        <v-card-text class="text-center" v-if="footerLinks.length > 0">
          <a :href="footerLink.url" target="_blank" :class="`text-h6 ${footerLinks.length > 1 ? 'mr-10' : ''}`" v-for="footerLink in footerLinks" :key="footerLink.id"
             v-dompurify-html="footerLink.name"/>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================
import {Roles} from '@/common/utils/security/Roles';
import SecUtil from '@/common/utils/security/SecUtil';
import {Icons, LoginManager} from 'client-core';
//================= [VUE COMPONENTS] ==========================
import UserSwitch from '@/components/admin/UserSwitch.vue';
import {tourOptions} from '@/composables/tour/useTour';
import {
  returnUser,
  logoUrl,
  socialMedias,
  footerLinks,
} from '@/composables/layout/baseLayout';
import {initialized, logout} from '@/composables/layout/layoutHandler';
import {computed, onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {useVOnboarding, VOnboardingWrapper} from 'v-onboarding';
import {useCoachingStore} from '@/common/store';
import MenuPartnerShops from '@/layout/menu/MenuPartnerShops.vue';
import MenuCalendar from '@/layout/menu/MenuCalendar.vue';
import MenuNotifications from '@/layout/menu/MenuNotifications.vue';
import CcImage from '@/components/common/file/CcImage.vue';

const mainOnboardingWrapper = ref(null);
const {start} = useVOnboarding(mainOnboardingWrapper);

const router = useRouter();
const i18n = useI18n();
const store = useCoachingStore();

const showSideNav = ref(true);
const withSideNav = ref(true);

const tourSteps = [
  {
    attachTo: {
      element: '[data-v-main-step="0"]',
    },
    content: {
      description: i18n.t('layout.tour.steps.0.content'),
    },
  },
  {
    attachTo: {
      element: '[data-v-main-step="1"]',
    },
    content: {
      description: i18n.t('layout.tour.steps.1.content'),
    },
  },
  {
    attachTo: {
      element: '[data-v-main-step="2"]',
    },
    content: {
      description: i18n.t('layout.tour.steps.2.content'),
    },
  },
  {
    attachTo: {
      element: '[data-v-main-step="3"]',
    },
    content: {
      description: i18n.t('layout.tour.steps.3.content'),
    },
  },
  {
    attachTo: {
      element: '[data-v-main-step="4"]',
    },
    content: {
      description: i18n.t('layout.tour.steps.4.content'),
    },
  },
];

const items = computed(() => {
  const navigation = [];
  if(store.mu)
    navigation.push({title: i18n.t('navigation.agreement'), path: 'agreement', icon: Icons.FILE_SIGN, active: 'agreement'});
  if(SecUtil.hasRoles(Roles.ROLE_CUSTOMER_INDEX))
    navigation.push({title: i18n.t('navigation.customer'), path: 'customerList', icon: Icons.BRIEFCASE_ACCOUNT, active: 'customer'});
  if(SecUtil.hasRoles(Roles.ROLE_MODULE_INDEX))
    navigation.push({title: i18n.t('navigation.module'), path: 'moduleList', icon: Icons.BALLOT, active: 'module'});
  if(SecUtil.hasRoles(Roles.ROLE_COURSE_INDEX))
    navigation.push({title: i18n.t('navigation.course'), path: 'courseList', icon: Icons.FILE_COMPARE, active: 'course'});
  if(SecUtil.hasRoles(Roles.ROLE_USER_INDEX))
    navigation.push({title: i18n.t('navigation.user'), path: 'userList', icon: Icons.ACCOUNT_BOX, active: 'user'});
  if(SecUtil.hasRoles(Roles.ROLE_FILE_SYSTEM_INDEX))
    navigation.push({title: i18n.t('navigation.fileSystem'), path: 'fileSystemList', icon: Icons.FILE_MULTIPLE, active: 'fileSystem'});
  if(SecUtil.hasRoles(Roles.ROLE_LIVE_COACHING_INDEX))
    navigation.push({title: i18n.t('navigation.liveCoaching'), path: 'liveCoaching', icon: Icons.HEADSET, active: 'liveCoaching'});
  if(SecUtil.coaching('recipe')) {
    navigation.push({
      title: i18n.t('navigation.recipe.label'), icon: Icons.CHEF_HAT,
      active: ['recipe', 'cookbook'],
      items: [
        {title: i18n.t('navigation.recipe.recipeList'), path: 'recipeList', icon: Icons.CHEF_HAT, active: 'recipe'},
        {title: i18n.t('navigation.recipe.cookBook'), path: 'cookbook', icon: Icons.BOOK_OPEN_VARIANT, active: 'cookbook'},
      ],
    });
  }
  if(SecUtil.coaching('statistic') && store.type === 'admin')
    navigation.push({title: i18n.t('navigation.statistic'), path: 'statistic', icon: Icons.CHART_LINE, active: 'statistic'});
  if(SecUtil.hasRoles(Roles.ROLE_NOTIFICATION_INDEX))
    navigation.push({title: i18n.t('navigation.notification.label'), path: 'notification', icon: Icons.BELL, active: 'notification'});
  if(SecUtil.hasRoles(Roles.ROLE_CHANGELOG_INDEX))
    navigation.push({title: i18n.t('navigation.changelog'), path: 'changelogList', icon: Icons.BULLHORN_OUTLINE, active: 'changelog'});
  if(SecUtil.hasRoles(Roles.ROLE_CUSTOMER_SETTINGS_INDEX))
    navigation.push({title: i18n.t('navigation.settings'), path: 'settings', icon: Icons.COG_OUTLINE, active: 'settings'});
  // add profile link to navigation only for mobile size
  navigation.push({title: i18n.t('navigation.profile'), css: 'd-flex d-md-none', path: 'userProfile', icon: Icons.CARD_ACCOUNT_DETAILS_OUTLINE, active: 'profile'});

  return navigation;
});

const isActive = (item) => {
  if(!router.currentRoute.value.meta.hasOwnProperty('active'))
    return false;

  if(Array.isArray(item.active)) {
    return item.active.includes(router.currentRoute.value.meta.active);
  } else {
    return router.currentRoute.value.meta.active === item.active;
  }
};
const startMainTour = () => {
  start();
};
const moveToWhatsNew = () => {
  router.push({name: 'whatsNew'});
};

onMounted(async() => {
  if(!LoginManager.isLoggedIn())
    return;

  if(window.screen.width < 960)
    withSideNav.value = false;
});

</script>

<style scoped>

</style>
