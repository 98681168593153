<template>
  <v-dialog v-model="dialog" :width="liveCoaching !== undefined ? (liveCoaching.permanent && showEditType ? 350 : 700) : 350" persistent>
    <template #activator="{ props }">
      <v-list-item tag="a" v-bind="props" @click="loadLiveCoaching">
        <template #prepend>
          <v-icon :icon="Icons.EDIT"/>
        </template>
        <v-list-item-title>{{ $t('common.edit') }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('liveCoaching.edit.headline') }}
      </v-card-title>
      <template v-if="liveCoaching !== undefined">
        <template v-if="event.type === 'extended'">
          <live-coaching-single-form ref="liveCoachingSingleForm"/>
        </template>
        <template v-else-if="event.type === 'cancel'">
          <v-card-text>
            <cc-alert-info :text="$t('liveCoaching.form.edit.permanent.cancel.noEdit')"/>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" @click="closeDialog()">
              {{ $t('common.cancel') }}
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else-if="event.type === 'normal'">
          <template v-if="liveCoaching.permanent">
            <template v-if="showEditType">
              <v-card-text>
                <v-radio-group class="mt-5" v-model="editType">
                  <v-radio value="series" @click="editType = 'series'" :label="$t('liveCoaching.form.edit.permanent.series')"></v-radio>
                  <v-radio value="single" @click="editType = 'single'" :label="$t('liveCoaching.form.edit.permanent.single')"></v-radio>
                  <v-radio value="cancel" @click="editType = 'cancel'" :label="$t('liveCoaching.form.edit.permanent.cancel.radio')"></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn variant="elevated" color="default" @click="closeDialog()">
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn variant="elevated" color="primary" @click="continueToEdit()">
                  {{ $t('common.continue') }}
                </v-btn>
              </v-card-actions>
            </template>
            <template v-else>
              <live-coaching-form v-if="editType === 'series'" ref="liveCoachingFormRef" type="edit"/>
              <live-coaching-single-form v-else-if="editType === 'single'" ref="liveCoachingSingleFormRef"/>
            </template>
          </template>
          <template v-else>
            <live-coaching-form type="edit"/>
          </template>
        </template>
      </template>
      <cc-loading-data-spinner v-else/>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
//================= [CSS] =====================================

//================= [JS] ======================================
import LiveCoachingApi from '@/common/api/liveCoaching/LiveCoachingApi';
import {Icons, ResponseVerifier, ErrorDialog, useEventBus, SweetAlertConfig, ApiClient} from 'client-core';
import LiveCoachingExtendedApi from '@/common/api/liveCoaching/LiveCoachingExtendedApi';
import Swal from 'sweetalert2';
import {Events} from '@/common/utils/Events';
//================= [VUE COMPONENTS] ==========================
import LiveCoachingForm from '@/components/liveCoaching/LiveCoachingForm.vue';
import LiveCoachingSingleForm from '@/components/liveCoaching/LiveCoachingSingleForm.vue';
import {ILiveCoaching, ILiveCoachingExtended} from '@/types/components/liveCoaching/ILiveCoaching';
import {onMounted, provide, ref} from 'vue';
import {useI18n} from 'vue-i18n';

const i18n = useI18n();
const eventBus = useEventBus();

const dialog = ref(false);
const editType = ref<string>('series');
const showEditType = ref(true);
const liveCoaching = ref<ILiveCoaching>();
const liveCoachingExtended = ref<ILiveCoachingExtended>({} as ILiveCoachingExtended);
const event = ref();
const liveCoachingFormRef = ref();
const liveCoachingSingleFormRef = ref();

provide('liveCoaching', liveCoaching);
provide('liveCoachingExtended', liveCoachingExtended);

const setEvent = (e) => {
  event.value = e;
};

const closeDialog = () => {
  event.value = null;
  liveCoaching.value = undefined;
  dialog.value = false;
  showEditType.value = true;
  editType.value = 'series';
};

const continueToEdit = async() => {
  if(editType.value === 'single') {
    const response = await ApiClient.get(LiveCoachingApi.extended({id: event.value.id, date: event.value.date, start: event.value.start, end: event.value.end})).catch(() => {
      new ErrorDialog();
    });
    if(ResponseVerifier.verify(response)) {
      liveCoachingExtended.value = response.data;
      showEditType.value = false;
    }
  } else if(editType.value === 'cancel') {
    await Swal.fire({
      title: i18n.t('liveCoaching.form.edit.permanent.cancel.title'),
      html: i18n.t('liveCoaching.form.edit.permanent.cancel.text'),
      ...SweetAlertConfig.delete(i18n.t('liveCoaching.form.edit.permanent.cancel.confirm')),
      preConfirm: async() => {
        const response = await ApiClient.post(LiveCoachingApi.cancel({id: event.value.id, date: event.value.date, start: event.value.start, end: event.value.end})).catch(() => {
          new ErrorDialog();
        });
        if(ResponseVerifier.verify(response)) {
          eventBus.emit(Events.NOTIFY, i18n.t('liveCoaching.form.edit.permanent.cancel.success'));
          eventBus.emit(Events.LIVE_COACHING_FORM_SUBMITTED);
          closeDialog();
        }
      },
    });
  } else {
    showEditType.value = false;
  }
};

const loadLiveCoaching = async() => {
  const response = await ApiClient.get(event.value.type === 'normal' ? LiveCoachingApi.api(event.value.id) : LiveCoachingExtendedApi.api(event.value.id)).catch(() => {
    new ErrorDialog();
  });

  if(ResponseVerifier.verify(response, true))
    liveCoaching.value = response.data;
};

onMounted(() => {
  eventBus.on(Events.CLOSE_LIVE_COACHING_FORM, () => {
    closeDialog();
  });
});

defineExpose({setEvent});
</script>

<style scoped>

</style>
