//================= [JS] ======================================
import i18n from '@/common/i18n';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.recipe.recipeList'),
        name: 'recipeList',
        component: () => import('@/components/recipe/RecipeList.vue'),
        meta: {
            title: i18n.global.t('titles.recipe.recipeList'),
            active: 'recipe',
            coaching: 'recipe',
            layout: 'flex',
        },
    },
    {
        path: i18n.global.t('routes.recipe.recipeShow'),
        name: 'recipeShow',
        component: () => import('@/components/recipe/RecipeShow.vue'),
        meta: {
            title: i18n.global.t('titles.recipe.recipeShow'),
            active: 'recipe',
            coaching: 'recipe',
            layout: 'flex',
        },
    },
    {
        path: i18n.global.t('routes.recipe.recipeSearch'),
        name: 'recipeSearch',
        component: () => import('@/components/recipe/RecipeSearch.vue'),
        meta: {
            title: i18n.global.t('titles.recipe.recipeSearch'),
            active: 'recipe',
            coaching: 'recipe',
            layout: 'flex',
        },
    },
    {
        path: i18n.global.t('routes.recipe.cookbook'),
        name: 'cookbook',
        component: () => import('@/components/recipe/cookbook/CookbookList.vue'),
        meta: {
            title: i18n.global.t('titles.recipe.cookbook'),
            active: 'cookbook',
            coaching: 'recipe',
            layout: 'flex',
        },
    },
    {
        path: i18n.global.t('routes.recipe.cookbookShow'),
        name: 'cookbookShow',
        component: () => import('@/components/recipe/cookbook/CookbookShow.vue'),
        meta: {
            title: i18n.global.t('titles.recipe.cookbookShow'),
            active: 'cookbook',
            coaching: 'recipe',
            layout: 'flex',
        },
    },
    {
        path: i18n.global.t('routes.recipe.create'),
        name: 'recipeCreate',
        component: () => import('@/components/recipe/RecipeCreate.vue'),
        meta: {
            title: i18n.global.t('titles.recipe.create'),
            active: 'cookbook',
            coaching: 'recipe',
            layout: 'flex',
        },
    },
    {
        path: i18n.global.t('routes.recipe.edit'),
        name: 'recipeEdit',
        component: () => import('@/components/recipe/RecipeEdit.vue'),
        meta: {
            title: i18n.global.t('titles.recipe.edit'),
            active: 'cookbook',
            coaching: 'recipe',
            layout: 'flex',
        },
    },
];
