import DOMPurify from 'dompurify';
import {useCoachingStore} from '@/common/store';
import {SecUtilHasRoles} from '@/types/common/utils/SecUtilInterfaces';

export default class SecUtil {

    public static hasRoles(roles: string): boolean {
        const store = useCoachingStore();
        let access = false;
        if(store.roles.length === 0)
            return access;

        if(roles.constructor === String) {
            access = store.roles.some(r => r === roles);
        }
        return access;
    }

    public static hasMultipleRoles(roles: SecUtilHasRoles): boolean {
        const store = useCoachingStore();
        let access = false;
        if(roles.or !== undefined) {
            let orAccess = false;
            roles.or.forEach((role: string) => {
                if(store.roles.some(r => r === role))
                    orAccess = true;
            });
            access = orAccess;
        } else if(roles.and !== undefined) {
            let andAccess = true;
            roles.and.forEach((role: string) => {
                if(!store.roles.some(r => r === role))
                    andAccess = false;
            });
            access = andAccess;
        }
        return access;
    }

    public static coaching(property: string): boolean {
        const store = useCoachingStore();
        // @ts-ignore
        return store.coaching[property];
    }

    public static sanitize(text: string | undefined) {
        if(text === undefined)
            return '';
        return DOMPurify.sanitize(text, {USE_PROFILES: {html: true}});
    }
}
