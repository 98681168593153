import CommonApi from '@/common/api/CommonApi';
import UserNotificationApi from '@/common/api/notification/UserNotificationApi';
import UserSwitchApi from '@/common/api/user/UserSwitchApi';
import {ref} from 'vue';
import router from '@/common/router';
import {useCoachingStore} from '@/common/store';
import BaseApi from '@/common/api/BaseApi';
import {ApiClient, ErrorDialog, LoginManager} from 'client-core';
import {INotification, IUserSwitchData} from '@/types/composables/layout/IBaseLayout';
import {IFooterLink, IPartnerShop, ISocialMedia} from '@/types/layout/ILayoutInterfaces';

export const partnerShops = ref<IPartnerShop[]>([]);
export const socialMedias = ref<ISocialMedia[]>([]);
export const footerLinks = ref<IFooterLink[]>([]);
export const shortCalendar = ref(false);
export const showNotification = ref(false);
const oldNotificationCount = ref(0);
export const notifications = ref<INotification[]>([]);

export function logoUrl() {
    const store = useCoachingStore();
    return `${BaseApi.IMAGE_URL}/files/${store.customer}/layout/logo.jpg`;
}

export async function loadFooterLinks() {
    await ApiClient.get<IFooterLink[]>(CommonApi.footerLinks()).then((response) => {
        if(response.status === 200)
            footerLinks.value = response?.data;
    }).catch(() => {
        new ErrorDialog();
    });
}

export async function loadSocialMedia() {
    await ApiClient.get<ISocialMedia[]>(CommonApi.socialMedia()).then((response) => {
        if(response.status === 200)
            socialMedias.value = response?.data;
    }).catch(() => {
        new ErrorDialog();
    });
}

export async function loadPartnerShops() {
    await ApiClient.get<IPartnerShop[]>(CommonApi.partnerShop()).then((response) => {
        if(response.status === 200)
            partnerShops.value = response?.data;
    }).catch(() => {
        new ErrorDialog();
    });
}

export async function loadNotifications() {
    await ApiClient.get<INotification[]>(UserNotificationApi.api(), false).then((response) => {
        if(response.status === 200) {
            notifications.value = response?.data;
            const menuNotification = document.getElementById('menuNotifications');
            if(notifications.value.length > oldNotificationCount.value) {
                menuNotification?.classList.add('shake');
                oldNotificationCount.value = notifications.value.length;
            } else {
                menuNotification?.classList.remove('shake');
            }
        }
    }).catch(() => {
        new ErrorDialog();
    });
}

export async function removeNotification(notificationId: string) {
    let notification = null;
    notifications.value.forEach((noti) => {
        if(noti.id === notificationId)
            notification = noti;
    });
    // @ts-ignore
    notifications.value.removeItem(notification);
    await ApiClient.delete(UserNotificationApi.api(notificationId)).catch(() => {
        new ErrorDialog();
    });
}

export async function returnUser() {
    const store = useCoachingStore();
    await ApiClient.post<IUserSwitchData>(UserSwitchApi.api()).then(async(response) => {
        if(response.status === 200) {
            const responseData = response?.data;
            responseData.usn = undefined;
            store.setResponseState(response?.data);
            LoginManager.setAccessToken(response?.data.accessToken);
            await router.push({name: 'login'});
        }
    }).catch(() => {
        new ErrorDialog();
    });
}

export async function loadLayout() {
    if(LoginManager.isLoggedIn()) {
        await loadFooterLinks();
        await loadSocialMedia();
        await loadPartnerShops();
        await loadNotifications();
    }
}
