export const Roles = Object.freeze({
    ROLE_MODULE_INDEX: '56e4f625-c6cc-4bc3-af0b-0316685f4ef8',
    ROLE_MODULE_CREATE: 'e681abba-2597-48d3-a683-7576d54e92a4',
    ROLE_MODULE_EDIT: '0f4c2fde-0fac-485d-832b-74159b8d72ca',
    ROLE_MODULE_DELETE: '66727863-ece2-49ad-a16d-b1f9806b2442',
    ROLE_FILE_SYSTEM_INDEX: 'aa27abda-2df9-4c67-ba59-19ef5f93ad0c',
    ROLE_FILE_SYSTEM_CREATE: '8761fd0a-1bba-4907-9dc5-0c149ac559c9',
    ROLE_FILE_SYSTEM_EDIT: '30af3578-a11e-489e-a8bf-84380aa03b49',
    ROLE_FILE_SYSTEM_DELETE: '1084826c-8ca5-4973-b43c-467909b12b70',
    ROLE_COURSE_INDEX: 'baa1f2d5-65f7-4a1d-8469-edfc988e3f84',
    ROLE_COURSE_CREATE: 'f2630df4-b0bc-4328-8957-52dc0531afd0',
    ROLE_COURSE_EDIT: '762aa5a9-2b3c-4945-8084-8d736beba0e7',
    ROLE_COURSE_DELETE: 'f2f518c4-5197-45eb-a542-c2f0f4d6498e',
    ROLE_LIVE_COACHING_INDEX: '8f6780e8-ae37-4d71-9091-2872f5a3e98a',
    ROLE_LIVE_COACHING_CREATE: '9a2e59b0-d414-4e3b-8415-d2230887351f',
    ROLE_LIVE_COACHING_EDIT: '76f13086-ab97-4942-8e4f-2386d2567421',
    ROLE_LIVE_COACHING_DELETE: 'c6d9f344-7c10-49e1-a21f-b11eba78633e',
    ROLE_USER_INDEX: 'c8cb4f27-7bd2-40bd-9424-c3b792cbf9b0',
    ROLE_USER_CREATE: 'db09ba3b-1dc6-4a38-9a7e-5c4397a5f3fd',
    ROLE_USER_EDIT: 'c5b8956e-a505-4641-ad3f-bc1897b6f8cb',
    ROLE_USER_DELETE: 'de8434ea-e434-4acd-ae04-86c66a6e6f6e',
    ROLE_CUSTOMER_SETTINGS_INDEX: '7d63dd9c-8ad4-4aee-b03b-a73265569258',
    ROLE_USER_SWITCH_INDEX: '842b434d-e1a9-42aa-afd3-c56a8cd25955',
    ROLE_CUSTOMER_INDEX: '05f8397d-a169-445b-ad84-304fbe5b7817',
    ROLE_CUSTOMER_CREATE: '11d4a78f-bdbc-44d4-aa65-30913ef3ef99',
    ROLE_CUSTOMER_EDIT: 'ece56580-7773-4e59-82e5-ce2fe1b74113',
    ROLE_CUSTOMER_DELETE: '39037b91-012e-4f9f-9370-a8c77b85e4b8',
    ROLE_NOTIFICATION_INDEX: 'e4f2a408-c789-4d06-a16f-202d2a1c6846',
    ROLE_NOTIFICATION_CREATE: 'fdd56db9-c4ba-41af-9806-5a978c4f70e2',
    ROLE_NOTIFICATION_EDIT: 'feaccfbf-484f-445d-bc1d-1c92bf0dad71',
    ROLE_NOTIFICATION_DELETE: 'aef752aa-1505-4f57-8fff-feded6cc8df0',
    ROLE_CHANGELOG_CREATE: '8f5dc9c6-92e5-410a-9a8d-32fecb83e6b3',
    ROLE_CHANGELOG_EDIT: '907829bb-3300-4cc6-a3b0-99d39aae4cec',
    ROLE_CHANGELOG_DELETE: '8084eb35-6459-4fd9-9629-9623f49ea7ce',
    ROLE_CHANGELOG_INDEX: '16c9c598-c182-4074-97aa-9255fc5d682b',
});
