//================= [JS] ======================================
import i18n from '@/common/i18n';
import {Roles} from '@/common/utils/security/Roles';
//================= [VUE COMPONENTS] ==========================

export default [
    {
        path: i18n.global.t('routes.customer.customerList'),
        name: 'customerList',
        component: () => import('@/components/customer/CustomerList.vue'),
        meta: {
            title: i18n.global.t('titles.customer.customerList'),
            active: 'customer',
            roles: [Roles.ROLE_CUSTOMER_INDEX],
        },
    },
    {
        path: i18n.global.t('routes.customer.customerEdit'),
        name: 'customerEdit',
        component: () => import('@/components/customer/CustomerEdit.vue'),
        meta: {
            title: i18n.global.t('titles.customer.customerEdit'),
            active: 'customer',
            roles: [Roles.ROLE_CUSTOMER_EDIT],
        },
    },
    {
        path: i18n.global.t('routes.customer.customerCreate'),
        name: 'customerCreate',
        component: () => import('@/components/customer/CustomerCreate.vue'),
        meta: {
            title: i18n.global.t('titles.customer.customerCreate'),
            active: 'customer',
            roles: [Roles.ROLE_CUSTOMER_CREATE],
        },
    },
];
